.order-box {
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
    background-color: #fff;
    border-radius: 10px;
}

.order-id-wrap {
    margin-bottom: 10px;
    padding: 0px 15px;
    padding-top: 15px;
}

.order-id-wrap,
.order-id-detail {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 8px;
}

.order-id-detail p {
    color: rgba(138, 138, 138, 1);
    font-size: 13px;
    font-weight: 500;
}

.order-id-detail span {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 500;
}

.order-detail {
    display: flex;
    align-items: center;
    gap: 25px;
}

.order-detail span {
    color: #000;
    font-weight: 500;
    cursor: pointer;
}

.order-detail p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95px;
    color: #000;
    font-weight: 500;
}

.order-detail-more p {
    white-space: inherit;
    overflow: unset;
    text-overflow: unset;
    width: 95px;
    color: #000;
    font-weight: 500;
    word-break: break-all;
}

.payment-detail {
    text-align: end;
}

.payment-detail p {
    font-weight: 500;
    font-size: 14px;
}

.payment-detail span {
    color: #F90000;
    font-weight: 500;
    font-size: 14px;
    display: none;
}

.order-detail-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #C7CCD1;
    padding-bottom: 10px;
    padding: 0px 15px;
    padding-bottom: 10px;
}

.order-price-detail {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 8px;
}

.order-price-detail:last-child {
    margin-bottom: 0px;
}

.order-price-detail p {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    max-width: 105px;
    width: 100%;
}

.order-price-detail span {
    font-weight: 600;
    color: #403C3C;
    white-space: nowrap;
    font-size: 14px;
}

.order-price-wrap .reject-btn {
    border: 1px solid #FFB800;
    border-radius: 5px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
    padding: 5px 15px;
}

.order-price-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.order-price-wrap .confirm-btn {
    background-color: #FFB800 !important;
    border-radius: 5px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
    padding: 6px 15px;
}

.order-btn {
    display: flex;
    gap: 5px;
}

.MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected {
    color: #FFB800 !important;
    font-weight: bold;
    border-bottom: 3px solid;
}

.css-1aquho2-MuiTabs-indicator{
    display: none;
}

.MuiTabs-flexContainer .MuiTab-textColorPrimary {
    text-transform: capitalize !important;
    color: #000;
    font-weight: 500;
}

.MuiTabs-fixed .MuiTabs-indicator {
    height: 4px;
    background-color: #FFB800 !important;
    border-radius: 5px;
}

.show-more-btn {
    text-align: center;
    margin: 25px 0px;
}

.show-more-btn button {
    border: 1px solid #FFB800;
    border-radius: 5px;
    font-weight: 500;
    padding: 5px 35px;
    color: #000;
    text-transform: capitalize;
}

.input-box {
    position: relative;
    max-width: 450px;
    width: 100%;
}

.input-box input {
    outline: none;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    color: #000;
    font-weight: 500;
    padding-left: 25px;
    max-width: 450px;
    width: 100%;
}

.input-box .filter-btn {
    background-color: #FFB800 !important;
    color: #000000;
    text-transform: capitalize;
    background-image: url(../../assets/img/calendar.png);
    background-position: left;
    background-size: auto;
    background-repeat: no-repeat;
    max-width: 150px;
    width: 100%;
    background-position-x: 10px;
    justify-content: end;
    height: 40px;
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    top: 50%;
    right: 5px;
    position: absolute;
    transform: translateY(-50%);
}

.order-section .refresh-btn {
    border: 1px solid #FFB800;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 8px 45px;
    margin-right: 20px;
    display: flex;
    margin-left: auto;
    margin-bottom: 20px;
    margin-top: 20px;
}

.order-section .refresh-btn img {
    margin-right: 5px;
}

.search-filled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.search-filled input {
    padding: 10px 10px;
    padding-left: 40px !important;
    color: #000;
    font-weight: 500;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    background-color: #fff;
    border-radius: 10px;
}

.search-filled input::placeholder {
    color: #000;
    font-weight: 500;
    opacity: 1 !important;
}

.search-filled .MuiInputBase-root {
    padding-left: 0px;
}

.search-filled fieldset,
.payment-dropdown fieldset {
    display: none;
}

.payment-dropdown {
    max-width: 250px;
    width: 100%;
    margin-bottom: 20px !important;
}

.payment-dropdown input {
    padding: 10px 14px !important;
    border: 1px solid #D9D9D9;
    background-color: #fff;
    border-radius: 10px;
}

.payment-dropdown #demo-simple-select {
    padding: 10px 14px !important;
    border: 1px solid #D9D9D9;
    background-color: #fff;
    border-radius: 10px;
}

.payment-dropdown label {
    color: #000;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
}

.order-btn p {
    color: #000;
    font-weight: 500;
}

.MuiModal-root .MuiMenuItem-root {
    justify-content: start !important;
}

@media (min-width:1500px) {

    .order-price-wrap .reject-btn,
    .order-price-wrap .confirm-btn {
        padding: 5px 30px;
    }
}

@media (min-width:991px) {
    .order-price-wrap {
        flex-wrap: wrap;
    }
}

@media (max-width:991px) {
    .order-price-detail {
        gap: 10px;
    }
}

@media (max-width:767px) {
    .payment-dropdown {
        margin-bottom: 0px !important;
    }

    .order-section .refresh-btn {
        display: none;
    }

    .filled-wrap {
        padding: 16px 0 !important;
    }

}

@media (max-width:557px) {
    .order-detail img {
        width: 70px;
    }

    .payment-detail p,
    .order-id-detail span,
    .order-id-detail p,
    .order-price-detail span,
    .payment-detail span,
    .order-detail p {
        font-size: 12px;
    }

    .order-price-detail {
        margin-bottom: 5px;
    }

    .order-price-detail p {
        font-size: 10px;
    }

    .order-price-detail span {
        font-size: 10px;
    }

    .graph-title h5 {
        font-size: 15px;
    }

    .graph-title p {
        font-size: 14px;
    }

    .order-btn {
        margin-top: 15px;
    }

    .order-price-wrap {
        flex-wrap: wrap;
        align-items: end;
    }

    .order-price-wrap .reject-btn,
    .order-price-wrap .confirm-btn {
        padding: 5px;
        font-size: 12px;
    }

    .order-detail-wrap {
        flex-wrap: wrap;
    }

    .payment-detail {
        display: block;
        margin-left: auto;
    }

    .payment-detail p {
        color: #000;
    }

    .payment-detail span {
        display: none;
    }

    .input-box .filter-btn {
        background-image: unset;
        max-width: max-content;
    }

    .graph-section .refresh-btn {
        display: none;
    }


    .payment-dropdown {
        max-width: 100%;
    }

    /* .search-filled {
        padding-bottom: 16px;
    } */
}

@media (max-width:400px) {
    .order-btn {
        width: 100%;
        justify-content: space-between;
    }

    .order-price-wrap>div {
        width: 100%;
    }

    .order-btn {
        margin-top: 10px;
    }

    .order-price-detail {
        justify-content: space-between;
    }

    .order-id-wrap {
        flex-wrap: wrap;
        gap: 5px;
    }

    .order-id-detail {
        width: 100%;
    }
}