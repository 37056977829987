.dashboard-card {
    padding-bottom: 15px;
}

.border-bottom {
    border-bottom: 1px solid #C7CCD1B2;
    margin-bottom: 20px;
}

.graph-wrap {
    padding: 50px 0;
}
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff;
}

.spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.order-box {
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
    background-color: #fff;
    border-radius: 10px;
}

.order-id-wrap {
    margin-bottom: 10px;
    padding: 0px 15px;
    padding-top: 15px;
}

.order-id-wrap,
.order-id-detail {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 8px;
}

.order-id-detail p {
    color: rgba(138, 138, 138, 1);
    font-size: 13px;
    font-weight: 500;
}

.order-id-detail span {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 500;
}

.order-detail {
    display: flex;
    align-items: center;
    gap: 25px;
}

.order-detail span {
    color: #000;
    font-weight: 500;
    cursor: pointer;
}

.order-detail p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95px;
    color: #000;
    font-weight: 500;
}

.order-detail-more p {
    white-space: inherit;
    overflow: unset;
    text-overflow: unset;
    width: 95px;
    color: #000;
    font-weight: 500;
    word-break: break-all;
}

.payment-detail {
    text-align: end;
}

.payment-detail p {
    font-weight: 500;
    font-size: 14px;
}

.payment-detail span {
    color: #F90000;
    font-weight: 500;
    font-size: 14px;
}

.order-detail-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #C7CCD1;
    padding-bottom: 10px;
    padding: 0px 15px;
    padding-bottom: 10px;
}

.order-price-detail {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 8px;
}

.order-price-detail:last-child {
    margin-bottom: 0px;
}

.order-price-detail p {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    max-width: 105px;
    width: 100%;
}

.order-price-detail span {
    font-weight: 600;
    color: #403C3C;
    white-space: nowrap;
    font-size: 14px;
}

.order-price-wrap .reject-btn {
    border: 1px solid #FFB800;
    border-radius: 5px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
    padding: 5px 15px;
}

.order-price-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.order-price-wrap .confirm-btn {
    background-color: #FFB800 !important;
    border-radius: 5px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
    padding: 6px 15px;
}

.order-btn {
    display: flex;
    gap: 5px;
}

.MuiTabs-flexContainer .MuiTab-textColorPrimary.Mui-selected {
    color: #000000 !important;
    font-weight: bold;
}

.MuiTabs-flexContainer .MuiTab-textColorPrimary {
    text-transform: capitalize !important;
    color: #000;
    font-weight: 500;
}

.MuiTabs-fixed .MuiTabs-indicator {
    height: 4px;
    background-color: #FFB800 !important;
    border-radius: 5px;
}

.show-more-btn {
    text-align: center;
    margin: 25px 0px;
}

.show-more-btn button {
    border: 1px solid #FFB800;
    border-radius: 5px;
    font-weight: 500;
    padding: 5px 35px;
    color: #000;
    text-transform: capitalize;
}

.input-box {
    position: relative;
    max-width: 450px;
    width: 100%;
}

.input-box input {
    outline: none;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    color: #000;
    font-weight: 500;
    padding-left: 25px;
    max-width: 450px;
    width: 100%;
}

.input-box .filter-btn {
    background-color: #FFB800 !important;
    color: #000000;
    text-transform: capitalize;
    background-image: url(../../assets/img/calendar.png);
    background-position: left;
    background-size: auto;
    background-repeat: no-repeat;
    max-width: 150px;
    width: 100%;
    background-position-x: 10px;
    justify-content: end;
    height: 40px;
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    top: 50%;
    right: 5px;
    position: absolute;
    transform: translateY(-50%);
}

.graph-section .refresh-btn {
    border: 1px solid #FFB800;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 8px 45px;
}

.graph-section .refresh-btn img {
    margin-right: 5px;
}

@media (min-width:1500px) {

    .order-price-wrap .reject-btn,
    .order-price-wrap .confirm-btn {
        padding: 5px 30px;
    }
}

@media (min-width:991px) {
    .order-price-wrap {
        flex-wrap: wrap;
    }
}

@media (max-width:991px) {
    .order-price-detail {
        gap: 10px;
    }

    .border-bottom {
        display: none;
    }

    .graph-wrap {
        padding: 30px 0px;
    }
}

@media (max-width:557px) {
    .order-detail img {
        width: 70px;
    }

    .payment-detail p,
    .order-id-detail span,
    .order-id-detail p,
    .order-price-detail p,
    .order-price-detail span,
    .payment-detail span,
    .order-detail p {
        font-size: 12px;
    }

    .graph-title h5 {
        font-size: 15px;
    }

    .graph-title p {
        font-size: 14px;
    }

    .order-btn {
        margin-top: 15px;
    }

    .order-price-wrap {
        flex-wrap: wrap;
    }

    .graph-wrap {
        padding: 20px 0px;
    }


}