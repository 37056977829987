.partner-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.partner-detail input {
    padding: 10px 14px;
    padding-left: 0px;
    font-weight: 500;
    font-size: 14px;
}

.partner-detail input::placeholder {
    color: #000;
    font-weight: 500;
    opacity: 1;
    font-size: 14px;
}

.partner-detail .MuiInputBase-root {
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    background-color: #fff;
    border-radius: 10px !important;
}

.partner-detail .refresh-btn {
    background-color: #fff !important;
    border: 1px solid #FFB800;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 8px 35px;
}

.partner-detail .add-partner {
    background-color: #FFB800 !important;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px 35px;
    margin-left: 10px;
}

.search-box {
    max-width: 220px;
    width: 100%;
}

.search-box fieldset {
    display: none;
}

.partner-title {
    margin-bottom: 20px;
}

.partner-title h2 {
    font-size: 25px;
    color: #000000;
    font-weight: 500;
}

.data-table-detail .MuiSwitch-track {
    background-color: #B7B7B7 !important;
    opacity: 1 !important;
}

.data-table-detail .MuiSwitch-switchBase {
    color: #4F4F4F !important;
}

.data-table-detail .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #FFB800 !important;
}

.data-table-detail .MuiSwitch-switchBase.Mui-checked {
    color: #FF7A00 !important;
}

.MuiBreadcrumbs-li a {
    font-size: 18px;
    font-weight: 500;
}

.MuiBreadcrumbs-li p {
    font-size: 18px;
    font-weight: 500;
}

.add-partner-modal .modal-btn .submit-btn {
    margin-left: 0px !important;
}

.add-partner-modal .modal-btn {
    margin-top: 40px;
}

.add-partner-modal h2 {
    margin-bottom: 20px;
}

.blank-label {
    opacity: 0;
    /* display: none !important; */
}

@media (max-width:991px) {
    .partner-title h2 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .partner-title {
        margin-bottom: 0px;
    }
}

@media (max-width:767px) {
    .partner-detail {
        gap: 15px;
    }

    .partner-title h2 {
        font-size: 20px;
    }
}

@media (max-width:575px) {
    .partner-detail .add-partner {
        padding: 10px 25px;
    }

    .partner-detail .refresh-btn {
        padding: 8px 30px;
    }
}