.login-info {
    background-image: url(../../assets/img/signin-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;

}

.login-bg {
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    padding: 50px 20px;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
}

.login-bg p {
    color: #403C3C;
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
}

.username-detail {
    margin-top: 15px !important;
    max-width: 400px;
    width: 100%;
    margin: auto;
    text-align: start;
}

.login-info .language-dropdown .MuiSelect-select {
    font-size: 14px;
    padding-left: 0px;
    font-weight: 700;
    float: right;
}

.language-dropdown {
    text-align: end;
}

.language-dropdown .MuiInputBase-root {
    background-color: unset;
    box-shadow: unset;
    border: unset;
}

.language-dropdown fieldset {
    display: none;
}

.username-detail .MuiTextField-root {
    width: 100%;
}

.username-detail label {
    display: block;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.login-bg input {
    /* box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    background-color: #fff; */
    border-radius: 10px;
    padding: 9px 14px;
    border: 1px solid #D9D9D9;
}

.login-bg fieldset {
    display: none;
}

.login-bg .forget_pwd {
    color: #0094FF;
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin-top: 10px;
    text-decoration: none;
    text-align: end;
    margin-right: 30px;
}

.login-bg .sign-in-btn {
    background-color: #FFB800 !important;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    color: #000;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 25px;
    padding: 10px;
}

.login-bg .sign-in-btn span {
    display: none;
}

.username-detail .MuiInputBase-root {
    position: relative;
    padding-right: 0px;
    box-shadow: unset;
}

.login-bg .error {
    font-size: 14px;
    color: red;
    margin: 30px;
    text-align: center;
}

@media (max-width:991px) {
    .login-bg {
        width: 90%;
    }

    .login-bg p {
        font-size: 16px;
    }

    .username-detail label {
        font-size: 14px;
    }
}