.partner-wrap {
    width: calc(100% - 230px);
    margin-left: auto;
    padding: 40px;
    padding-top: 25px;
}

@media (max-width:991px) {
    .partner-wrap {
        width: 100%;
        padding-top: 15px;
    }
}

@media (max-width:767px) {
    .partner-wrap {
        padding: 15px;
    }
}