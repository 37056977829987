.icons55 {
  height: 64px;
  width: 64px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.image {
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background-color: var(--color-navajowhite);
  border-top: 1px solid var(--default-default-300);
  border-right: 1px solid var(--default-default-300);
  border-left: 1px solid var(--default-default-300);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.icons56,
.icons57 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.imageactions,
.top4 {
  display: flex;
  align-items: flex-start;
}
.top4 {
  width: 132px;
  margin: 0 !important;
  position: absolute;
  top: 8px;
  right: 8px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.imageactions {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

  position: relative;
  gap: var(--gap-3xs);
}
.empty-tag,
.name7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.name7 {
  align-self: stretch;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.empty-tag {
  display: inline-block;
  min-width: 10px;
}
.icons58 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
}
.div3,
.icons59 {
  position: relative;
}
.div3 {
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 10px;
}
.icons59 {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.tag1,
.tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
}
.tags {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.name8 {
  align-self: stretch;
  position: relative;
  font-size: var(--desktop-button-size);
  line-height: 21px;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.category-card8-1,
.text63 {
  border: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: column;
}
.text63 {
  align-self: stretch;
  border-radius: 0 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--default-default-0);
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-4xs);
  gap: var(--gap-5xs);
}
.category-card8-1 {
  height: 178px;
  width: 178px;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--desktop-h3-size);
  color: var(--default-default-900);
  font-family: var(--desktop-detail);
}

.new-updates{
  background-color: rgb(255, 222, 161);
    background-size: contain;
    background-position: center;
    color: #fff;
    font-size: 14px;
}
@media screen and (max-width: 450px) {
  .div3,
  .empty-tag,
  .name7 {
    font-size: var(--desktop-button-size);
    line-height: 19px;
  }
  .category-card8-1 {
    height: 150px;
    width: 150px;
  }
}
