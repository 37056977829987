.logo-icon {
  height: 32px;
  width: 96px;
  position: relative;
  object-fit: contain;
}
.avatar-icon {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-181xl);
}
.name {
  height: 20px;
  flex: 1;
  font-family: var(--desktop-detail);
  font-weight: 500;
  font-size: var(--desktop-h4-size);
  color: var(--default-default-900);
  width: auto;
}
.header,
.user {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user {
  width: 120px;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.header {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  justify-content: space-between;
  padding: var(--padding-sm) var(--padding-21xl) var(--padding-xs);
  top: 0;
  z-index: 99;
  position: sticky;
  gap: var(--gap-xl);
}
.label {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 61px;
}
.shape {
  height: 3px;
  width: 57px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: calc(50% - 28.5px);
  border-radius: var(--br-81xl) var(--br-81xl) 0 0;
  background-color: var(--primary-primary-500);
}
.indicator {
  align-self: stretch;
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.spacer {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge {
  width: 6px;
  height: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.building-blockstab-single-uni,
.state-layer,
.tab-contents {
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tab-contents {
  flex-direction: column;
  padding: var(--padding-sm) 0 0;
}
.building-blockstab-single-uni,
.state-layer {
  flex-direction: row;
}
.state-layer {
  padding: 0 var(--padding-25xl);
}
.building-blockstab-single-uni {
  min-width: 97px;
  color: var(--primary-primary-900);
}
.label1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 104px;
}
.spacer1 {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge1,
.tab-contents1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.badge1 {
  height: 6px;
  width: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.tab-contents1 {
  overflow: hidden;
  display: flex;
  padding: var(--padding-sm) 0;
  gap: var(--gap-9xs);
}
.state-layer1 {
  padding: 0 var(--padding-3xl);
}
.building-blockstab-single-uni1,
.state-layer1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.label2 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 63px;
}
.spacer2 {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge2,
.tab-contents2 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.badge2 {
  height: 6px;
  width: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.tab-contents2 {
  overflow: hidden;
  display: flex;
  padding: var(--padding-sm) 0;
  gap: var(--gap-9xs);
}
.state-layer2 {
  padding: 0 var(--padding-24xl);
}
.building-blockstab-single-uni2,
.state-layer2 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.top-bar {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}
.div {
  position: relative;
  font-weight: 600;
  display: inline-block;
  min-width: 80px;
}
.link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.a {
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
  min-width: 67px;
}
.link1 {
  flex-direction: row;
  justify-content: flex-start;
}
.link-parent,
.link1,
.links,
.second-link {
  display: flex;
  align-items: flex-start;
}
.second-link {
  flex-direction: column;
  justify-content: flex-start;
  color: var(--default-default-600);
  font-family: var(--desktop-detail);
  cursor: pointer;
}
.link-parent,
.links {
  flex-direction: row;
}
.links {
  justify-content: flex-start;
  gap: var(--gap-base);
}
.link-parent {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.data-label {
  align-self: stretch;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
}
.data-label,
.icons,
.text {
  position: relative;
}
.icons {
  height: 20px;
  width: 20px;
}
.text {
  font-size: var(--desktop-detail-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
  display: inline-block;
  min-width: 80px;
}
.button-base {
  cursor: pointer;
  border: 1px solid var(--default-default-400);
  padding: var(--padding-7xs) var(--padding-smi);
  background-color: transparent;
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  white-space: nowrap;
}
.button-base:hover {
  background-color: rgba(150, 148, 148, 0.09);
  border: 1px solid #969494;
  box-sizing: border-box;
}
.icons1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base1 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.icons2 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base2 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.icons3 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base3 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.icons4 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base4 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.icons5 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base5 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.icons6 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base6 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.icons7 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base7 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.icons8,
.text1 {
  position: relative;
}
.icons8 {
  height: 20px;
  width: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text1 {
  font-size: var(--desktop-detail-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
  display: inline-block;
  min-width: 84px;
}
.actions,
.button-base8,
.data-label-parent,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-base8 {
  cursor: pointer;
  border: 1px solid var(--primary-primary-500);
  padding: var(--padding-7xs) var(--padding-smi);
  background-color: var(--primary-primary-500);
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  overflow: hidden;
  gap: var(--gap-5xs);
  white-space: nowrap;
}
.button-base8:hover {
  background-color: var(--color-darkgoldenrod);
  border: 1px solid var(--color-darkgoldenrod);
  box-sizing: border-box;
}
.actions,
.data-label-parent,
.right {
  gap: var(--gap-9xs);
  max-width: 100%;
}
.actions,
.data-label-parent {
  align-self: stretch;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.data-label-parent {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.table-header-cell,
.table-header-cell1 {
  height: 44px;
  width: 52px;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
}
.table-header-cell1 {
  width: 108px;
}
.header-labels {
  text-decoration: none;
  flex: 1;
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-600);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header,
.table-header-cell2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header-cell2 {
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
  padding: var(--padding-sm-5) var(--padding-5xs) var(--padding-xs);
  min-width: 76px;
}
.text2 {
  flex: 1;
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-600);
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.table-header-cell3,
.table-header1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.table-header1 {
  flex: 1;
}
.table-header-cell3 {
  width: 366px;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
  padding: var(--padding-sm-5) var(--padding-5xs) var(--padding-xs);
}
.text3 {
  flex: 1;
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-600);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell4,
.table-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header2 {
  flex: 1;
}
.table-header-cell4 {
  width: 131px;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
  padding: var(--padding-sm-5) var(--padding-5xs) var(--padding-xs);
}
.text4 {
  flex: 1;
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-600);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell5,
.table-header3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header3 {
  flex: 1;
}
.table-header-cell5 {
  width: 95px;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
  padding: var(--padding-sm-5) var(--padding-5xs) var(--padding-xs);
}
.text5 {
  flex: 1;
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-600);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header4 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header-cell6,
.table-header-cell7 {
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
}
.table-header-cell6 {
  width: 131px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-sm-5) var(--padding-5xs) var(--padding-xs);
}
.table-header-cell7 {
  height: 44px;
  width: 40px;
}
.header1 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  height: auto;
}
.header1,
.lines,
.table-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.lines {
  flex: 1;
  flex-direction: column;
}
.table-content {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-5xs);
  box-sizing: border-box;
}
.div1,
.show {
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
  display: inline-block;
  min-width: 37px;
}
.div1 {
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--primary-primary-700);
  min-width: 15px;
}
.link2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.semicolon {
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-600);
  text-align: left;
  display: inline-block;
  min-width: 18px;
}
.link3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.div2 {
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-600);
  text-align: left;
  display: inline-block;
  min-width: 18px;
}
.link4,
.page-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-links {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.items {
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
  display: inline-block;
  min-width: 36px;
}
.line-settings {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.page-1-from {
  position: relative;
  font-size: var(--desktop-detail-size);
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
  display: inline-block;
  min-width: 92px;
}
.icons9 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base9 {
  width: 36px;
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  border: 1px solid var(--default-default-400);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-6xs);
}
.button-base10,
.button-base9,
.icons10 {
  overflow: hidden;
  flex-shrink: 0;
}
.icons10 {
  height: 20px;
  width: 20px;
  position: relative;
}
.button-base10 {
  width: 36px;
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  border: 1px solid var(--default-default-400);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-6xs);
}
.buttons,
.pages {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.pages {
  align-items: center;
  gap: var(--gap-base);
}
.bottom,
.data {
  align-self: stretch;
}
.bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.data {
  margin: 0;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-lg) var(--padding-lgi);
}
.data,
.link-parent-parent,
.topbar-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.link-parent-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.topbar-inner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-5xl) 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  color: var(--primary-primary-700);
  font-family: var(--font-poppins);
}
.topbar {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  max-width: calc(100% - 316px);
  text-align: center;
  font-size: var(--desktop-detail-size);
  color: var(--default-default-600);
  font-family: var(--desktop-detail);
}
.configuration,
.content,
.topbar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  align-self: stretch;
  flex-direction: row;
  max-width: 100%;
}
.configuration {
  width: 100%;
  position: relative;
  background-color: var(--bg);
  overflow: hidden;
  flex-direction: column;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1050px) {
  .topbar {
    max-width: 100%;
  }
  .content {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .actions {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .bottom,
  .right {
    flex-wrap: wrap;
  }
}
