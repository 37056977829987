.logo-icon2 {
  height: 32px;
  width: 96px;
  position: relative;
  object-fit: cover;
}
.avatar-icon2 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-181xl);
}
.name2 {
  height: 20px;
  flex: 1;
  font-family: var(--desktop-detail);
  font-weight: 500;
  font-size: var(--desktop-h4-size);
  color: var(--default-default-900);
  width: auto;
}
.header3,
.user2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user2 {
  width: 120px;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.header3 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  justify-content: space-between;
  padding: var(--padding-sm) var(--padding-21xl) var(--padding-xs);
  top: 0;
  z-index: 99;
  position: sticky;
  gap: var(--gap-xl);
}
.label6 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 61px;
}
.spacer6 {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge6,
.tab-contents6 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.badge6 {
  height: 6px;
  width: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.tab-contents6 {
  overflow: hidden;
  display: flex;
  padding: var(--padding-sm) 0;
  gap: var(--gap-9xs);
}
.state-layer6 {
  padding: 0 var(--padding-25xl);
}
.building-blockstab-single-uni6,
.state-layer6 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.label7,
.shape2 {
  position: relative;
}
.label7 {
  text-decoration: none;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 104px;
}
.shape2 {
  height: 3px;
  flex: 1;
  border-radius: var(--br-81xl) var(--br-81xl) 0 0;
  background-color: var(--primary-primary-500);
}
.indicator2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px var(--padding-11xs) 0;
}
.spacer7 {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge7 {
  width: 6px;
  height: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.building-blockstab-single-uni7,
.state-layer7,
.tab-contents7 {
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tab-contents7 {
  flex-direction: column;
  padding: var(--padding-sm) 0 0;
}
.building-blockstab-single-uni7,
.state-layer7 {
  flex-direction: row;
}
.state-layer7 {
  padding: 0 var(--padding-3xl);
}
.building-blockstab-single-uni7 {
  min-width: 97px;
  color: var(--primary-primary-900);
}
.label8 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 63px;
}
.spacer8 {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge8,
.tab-contents8 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.badge8 {
  height: 6px;
  width: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.tab-contents8 {
  overflow: hidden;
  display: flex;
  padding: var(--padding-sm) 0;
  gap: var(--gap-9xs);
}
.state-layer8 {
  padding: 0 var(--padding-24xl);
}
.building-blockstab-single-uni8,
.state-layer8 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.input,
.top-bar3 {
  align-self: stretch;
}
.top-bar3 {
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}
.input {
  height: 44px;
  font-family: var(--desktop-detail);
  font-weight: 600;
  font-size: var(--desktop-button-size);
  color: var(--default-default-600);
}
.input-dropdown-base,
.input-with-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-with-label {
  align-self: stretch;
}
.input-dropdown-base {
  flex: 1;
}
.icons11,
.text6 {
  position: relative;
}
.icons11 {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.text6 {
  text-decoration: none;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 35px;
}
.button-base11,
.buttons1 {
  display: flex;
  flex-direction: row;
}
.button-base11 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-lg);
  gap: var(--gap-5xs);
}
.buttons1 {
  width: 234px;
  align-items: flex-start;
  justify-content: flex-start;
}
.icons12,
.text7 {
  position: relative;
}
.icons12 {
  height: 20px;
  width: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text7 {
  font-size: var(--desktop-detail-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
  display: inline-block;
  min-width: 84px;
}
.actions1,
.button-base12 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button-base12 {
  cursor: pointer;
  border: 1px solid var(--primary-primary-500);
  padding: var(--padding-7xs) var(--padding-smi);
  background-color: var(--primary-primary-500);
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  overflow: hidden;
  justify-content: center;
  gap: var(--gap-5xs);
  white-space: nowrap;
}
.button-base12:hover {
  background-color: var(--color-darkgoldenrod);
  border: 1px solid var(--color-darkgoldenrod);
  box-sizing: border-box;
}
.actions1 {
  align-self: stretch;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.categories1 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 0px;
  cursor: pointer;
  font-size: 16px;
    font-weight: 400;
    cursor:'pointer'
}
.active {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 0px;
  cursor: pointer;
}
.bread-counts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.icons13 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.button-base13 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.icons14 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.bread-countsactions,
.button-base14,
.buttons2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button-base14 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-5xs);
}
.bread-countsactions,
.buttons2 {
  justify-content: flex-end;
  gap: var(--gap-5xs);
}
.bread-countsactions {
  align-self: stretch;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.icons15 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.add-category {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 105px;
}
.category-card-1 {
  border-radius: var(--br-5xs);
  display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
}
.add-category1,
.icons16 {
  position: relative;
  display: none;
}
.icons16 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.add-category1 {
  width: 105px;
  line-height: 24px;
  font-weight: 600;
}
.category-card1 {
  height: 252px;
  width: 252px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-category2,
.icons17 {
  position: relative;
  display: none;
}
.icons17 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.add-category2 {
  width: 105px;
  line-height: 24px;
  font-weight: 600;
}
.category-card2 {
  height: 252px;
  width: 252px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-category3,
.icons18 {
  position: relative;
  display: none;
}
.icons18 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.add-category3 {
  width: 105px;
  line-height: 24px;
  font-weight: 600;
}
.category-card3 {
  height: 252px;
  width: 252px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-category4,
.icons19 {
  position: relative;
  display: none;
}
.icons19 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.add-category4 {
  width: 105px;
  line-height: 24px;
  font-weight: 600;
}
.category-card4 {
  height: 252px;
  width: 252px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-category5,
.icons20 {
  position: relative;
  display: none;
}
.icons20 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.add-category5 {
  width: 105px;
  line-height: 24px;
  font-weight: 600;
}
.category-card5 {
  height: 252px;
  width: 252px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-category6,
.icons21 {
  position: relative;
  display: none;
}
.icons21 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.add-category6 {
  width: 105px;
  line-height: 24px;
  font-weight: 600;
}
.category-card6 {
  height: 252px;
  width: 252px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-category7,
.icons22 {
  position: relative;
}
.icons22 {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.add-category7 {
  width: 100%;
  line-height: 24px;
  font-weight: 600;
}
.category-card7 {
  height: 252px;
  width: 252px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.category-card71 {
  height: 178px;
  width: 178px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cards,
.categories,
.content3 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cards {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px 22.7px;
  text-align: center;
  font-size: var(--desktop-button-size);
  color: var(--default-default-900);
}
.categories,
.content3 {
  flex-direction: column;
}
.categories {
  gap: var(--gap-5xs);
  font-size: var(--desktop-h4-size);
}
.content3 {
  gap: var(--gap-5xl);
}
.edit,
.icons23 {
  position: relative;
}
.icons23 {
  height: 16px;
  width: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.edit {
  line-height: 20px;
  display: inline-block;
  min-width: 46px;
}
.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.icons24 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  z-index: 2;
}
.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-5xs);
}
.edit1,
.icons25 {
  position: relative;
}
.icons25 {
  height: 16px;
  width: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.edit1 {
  line-height: 20px;
  display: inline-block;
  min-width: 76px;
}
.left1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.icons26 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  z-index: 2;
}
.option1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-5xs);
}
.edit2,
.icons27 {
  position: relative;
}
.icons27 {
  height: 16px;
  width: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.edit2 {
  line-height: 20px;
  display: inline-block;
  min-width: 72px;
}
.left2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.icons28 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  z-index: 2;
}
.option2 {
  border-bottom: 1px solid var(--default-default-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-5xs) var(--padding-xs);
}
.option3 {
  align-self: stretch;
  height: 48px;
  font-family: var(--desktop-detail);
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.edit3,
.icons29 {
  position: relative;
}
.icons29 {
  height: 16px;
  width: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.edit3 {
  line-height: 20px;
  display: inline-block;
  min-width: 37px;
}
.left3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.icons30 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  z-index: 2;
}
.option4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) var(--padding-5xs);
  color: var(--error);
}
.actions2,
.card-actions {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.actions2 {
  width: 199px;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
  cursor: pointer;
}
.card-actions {
  width: 100%;
  right: -74px;
  flex-direction: row;
  top: 37px;
  justify-content: center;
  padding: 0 var(--padding-xl);
  max-width: 100%;
  /* margin-top: -1048px; */
  position: absolute;
  text-align: center;
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.inner-content,
.inner-content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.inner-content {
  flex: 1;
  flex-direction: column;
  min-height: 1192px;
}
.inner-content-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-5xl) 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--desktop-button-size);
}
.top-bar2 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--desktop-detail-size);
  color: var(--default-default-600);
  font-family: var(--desktop-detail);
}
.configuration-product-displa,
.content2,
.top-bar2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content2 {
  align-self: stretch;
  flex-direction: row;
  max-width: 100%;
}
.configuration-product-displa {
  width: 100%;
  position: relative;
  background-color: var(--bg);
  overflow: hidden;
  flex-direction: column;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1050px) {
  .top-bar2 {
    max-width: 100%;
  }
  .content2 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .actions1,
  .bread-countsactions {
    flex-wrap: wrap;
  }
  .category-card71 {
    height: 150px;
    width: 150px;
  }

}
