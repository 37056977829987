.review-card {
    border: 1px solid rgba(205, 205, 205, 0.49);
    background: #FFF;
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
    border-radius: 10px;
    padding: 10px;
}

.review-card-detail {
    display: flex;
}

.user-review-profile svg {
    font-size: 100px;
}

.review-star {
    display: flex;
    align-items: center;
}

.review-star li {
    padding: 0;
}

.review-star li svg {
    color: #C8C8C8;
}

.review-card-detail p {
    color: #8A8A8A;
    font-size: 14px;
    font-weight: 500;
}

.review-card .MuiAccordionSummary-root,
.MuiAccordionDetails-root {
    padding: 0px !important;
    justify-content: start;
}

.review-card .MuiAccordion-root {
    box-shadow: unset;
    background-color: transparent;
}

.review-card .MuiAccordion-root:before {
    display: none;
}

.review-card .MuiAccordionSummary-content {
    max-width: 100px;
}

.review-card .MuiAccordionSummary-content p,
.review-card .MuiAccordionSummary-expandIconWrapper svg {
    color: #0094FF !important;
}

.show-more-btn {
    width: 100%;
}

.show-more-btn button {
    border: 1px solid #FFB800;
    border-radius: 5px;
    font-weight: 500;
    padding: 5px 35px;
    color: #000;
    text-transform: capitalize;
}

.user-review-profile svg {
    cursor: pointer;
}