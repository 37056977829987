.website-detail {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    width: 100%;
    margin-left: 77px;
}

.website-detail a {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    color: #000;
    text-decoration: none;
    font-size: 14px;
}

.website-detail .profile-btn {
    background-color: #FFFFFF !important;
    box-shadow: 0px 2px 5px 2px rgba(79, 79, 79, 0.30);
    border-radius: 25px;
    text-transform: capitalize;
    gap: 8px;
}

.website-detail .profile-btn span {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.website-detail .profile-btn span:last-child {
    display: none;
}

/* 
.navbar-logo {
    width: 100%;
} */

.top-navbar {
    height: 80px;
}

.top-navbar .MuiPaper-elevation {
    background-color: #FFFFFF !important;
    box-shadow: unset;
}

.top-navbar .MuiPaper-elevation .MuiToolbar-root {
    padding: 10px 45px;
}

.toggle-btn {
    display: none !important;
}

.website-detail .MuiSelect-select {
    background-color: transparent;
    font-size: 14px;
    padding-left: 0px;
    font-weight: 700;
}

.MuiMenuItem-root {
    font-size: 16px !important;
    font-weight: 700 !important;
    justify-content: center !important;
}

.website-detail .MuiInputBase-root {
    background-color: #FFB800;
    box-shadow: unset;
}


.website-detail fieldset {
    display: none;
}

@media (max-width:991px) {
    .website-detail {
        justify-content: end;
    }

    .navbar-logo {
        max-width: 110px;
        margin: 0 auto;
        /* padding: 10px; */
    }

    .toggle-btn {
        display: block !important;
    }

    .profile-btn {
        display: none !important;
    }

    .mobile-sidebar {
        /* max-width: 100%; */
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
        position: fixed;
        right: 0;
        top: 80px;
        border-bottom-left-radius: 10px;
        z-index: 9;
        padding-left: 10px;
        padding-right: 10px;
    }

    .top-navbar .MuiPaper-elevation .MuiToolbar-root {
        padding: 5px 45px;
        height: 80px;
    }

    .mobile-sidebar li {
        cursor: pointer;
        padding: 15px;
        width: 100%;
        margin: 0 40px;
        gap: 15px;
        align-items: center;
        white-space: nowrap;
        transition: all 0.5s;
        /* justify-content: center; */
        max-width: 150px;
        color: #000000;
        font-weight: 500;
        padding: 10px 0px;
    }

    .website-detail a {
        margin-right: 15px;
        /* margin-top: -9px; */
        white-space: nowrap;
    }

    .website-detail {
        margin-left: 0px;
        margin-right: 20px;
    }

    .mobile-sidebar .sidebar-menu-active {
        background-color: #fff3d3;
    }

    .close-icon {
        width: 20px;
    }

    .toogle-icon {
        width: 30px;
    }

}

@media (max-width:767px) {

    .top-navbar .MuiPaper-elevation .MuiToolbar-root {
        padding: 10px;
        border-bottom: 1px solid #CBCAC8;
    }

    .website-detail a {
        font-size: 12px;
    }

    .navbar-logo {
        max-width: 90px;
    }

    .navbar-logo img {
        filter: blur(0);
    }
}