@tailwind base;
@tailwind components;
@tailwind utilities;



.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.modal-body {
  max-width: 550px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  filter: drop-shadow(3px 2px 5px rgba(205, 205, 205, 0.49));
  padding: 15px 25px;
  border-radius: 10px;
  outline: none;
}

.modal-body h2 {
  color: #000;
  font-weight: 500;
  font-size: 22px;
}

.modal-btn {
  display: flex;
}

.modal-btn .cancel-btn {
  box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
  background-color: #fff;
  border: 1px solid #FFB800;
  border-radius: 5px;
  color: #000;
  text-transform: capitalize;
  padding: 10px 25px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  min-width: 220px;
}

.modal-btn .submit-btn {
  box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
  background-color: #FFB800 !important;
  border-radius: 5px;
  color: #000;
  text-transform: capitalize;
  padding: 10px 25px;
  /* margin-right: 10px; */
  font-weight: 500;
  font-size: 16px;
  min-width: 220px;
}

.input-field-title label {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.input-field-title input,
#demo-simple-select {
  padding: 10px 14px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  color: #4F4F4F;
  font-weight: 500;
  font-size: 14px;
}

.input-field-title input::placeholder {
  color: #4F4F4F;
  opacity: 1;
  font-size: 14px;
}

.input-field-title fieldset {
  display: none;
}

.input-error input {
  border-color: #F90000;
}

.input-error span {
  color: #F90000;
  margin-left: 10px;
  font-size: 14px;
}

@media (max-width:1199px) {
  .modal-body {
    width: 90%;
  }
}

@media (max-width:991px) {
  .modal-btn .cancel-btn {
    padding: 5px;
    font-size: 14px;
    min-width: 160px;
  }

  .modal-btn .submit-btn {
    padding: 5px;
    font-size: 14px;
  }

  .modal-btn {
    margin: 0px;
  }
}

@media (max-width:767px) {
  .modal-body {
    width: 90%;
  }

  .modal-body h2 {
    font-size: 20px;
  }

  .user-edit-modal p {
    margin-top: 15px;
  }

  .modal-btn .cancel-btn,
  .submit-btn {
    width: 100% !important;
    margin-bottom: 15px;
    margin-right: 0px;
    font-size: 14px;
  }

  .modal-btn {
    margin: 15px 0;
    flex-wrap: wrap;
  }
}