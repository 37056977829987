.allergens-dropdown .MuiChip-filled {
    background-color: #FFB800 !important;
}

.allergens-dropdown .MuiSelect-outlined {
    padding: 7px 14px !important;
    border-bottom: 1px solid #000;
    border-radius: 0;
}


.allergens-dropdown fieldset {
    display: none;
}

.allergens-dropdown label {
    color: #000 !important;
    font-weight: 500;
    font-size: 14px;
}

.allergens-dropdown .MuiChip-label {
    margin-top: 8px;
}