.logo-icon4 {
  height: 32px;
  width: 96px;
  position: relative;
  object-fit: cover;
}
.avatar-icon4 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: var(--br-181xl);
}
.name4 {
  height: 20px;
  flex: 1;
  font-family: var(--desktop-detail);
  font-weight: 500;
  font-size: var(--desktop-h4-size);
  color: var(--default-default-900);
  width: auto;
}
.header5,
.user4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user4 {
  width: 120px;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.header5 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  justify-content: space-between;
  padding: var(--padding-sm) var(--padding-21xl) var(--padding-xs);
  top: 0;
  z-index: 99;
  position: sticky;
  gap: var(--gap-xl);
}
.label9 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 61px;
}
.shape3 {
  height: 3px;
  width: 57px;
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: calc(50% - 28.5px);
  border-radius: var(--br-81xl) var(--br-81xl) 0 0;
  background-color: var(--primary-primary-500);
}
.indicator3 {
  align-self: stretch;
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.spacer9 {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge9 {
  width: 6px;
  height: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.building-blockstab-single-uni9,
.state-layer9,
.tab-contents9 {
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tab-contents9 {
  flex-direction: column;
  padding: var(--padding-sm) 0 0;
}
.building-blockstab-single-uni9,
.state-layer9 {
  flex-direction: row;
}
.state-layer9 {
  padding: 0 var(--padding-25xl);
}
.building-blockstab-single-uni9 {
  min-width: 97px;
  color: var(--primary-primary-900);
}
.label10 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 104px;
}
.spacer10 {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge10,
.tab-contents10 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.badge10 {
  height: 6px;
  width: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.tab-contents10 {
  overflow: hidden;
  display: flex;
  padding: var(--padding-sm) 0;
  gap: var(--gap-9xs);
}

.product-add-input{
  min-width: 261px !important;
}

.product-add-input input {
  height: 7px;
  width: 100%;
}
.product-add-select{
  min-width: 261px !important;
}

.product-add-select input {
  height: 100%;
  width: 100%;
}

.state-layer10 {
  padding: 0 var(--padding-3xl);
}
.building-blockstab-single-uni10,
.state-layer10 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.label11 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 63px;
}
.spacer11 {
  height: 2px;
  width: 2px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge11,
.tab-contents11 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.badge11 {
  height: 6px;
  width: 6px;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  display: none;
  padding: var(--padding-11xs);
  box-sizing: border-box;
}
.tab-contents11 {
  overflow: hidden;
  display: flex;
  padding: var(--padding-sm) 0;
  gap: var(--gap-9xs);
}
.state-layer11 {
  padding: 0 var(--padding-24xl);
}
.building-blockstab-single-uni11,
.state-layer11 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.top-bar4 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}
.button-base15 {
  align-self: stretch;
  height: 40px;
  box-shadow: var(--shadow-xs);
}
.button-base-wrapper {
  width: 108px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
  box-sizing: border-box;
}
.add-product {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 34px;
  font-weight: 600;
  font-family: inherit;
}
.add-product-wrapper {
  width: 241px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
  box-sizing: border-box;
}
.icons31 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.close-button,
.head {
  display: flex;
  flex-direction: row;
}
.close-button {
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  cursor: pointer;
}
.head {
  align-self: stretch;
  border-bottom: 1px solid var(--default-default-300);
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-3xs) 0 var(--padding-5xs);
  gap: var(--gap-xl);
  text-align: center;
}
.predefined-fields {
  margin: 0;
  position: relative;
  font-size: var(--desktop-h3-size);
  line-height: 24px;
  font-weight: 600;
  font-family: inherit;
}
.checkbox-elements {
  margin: 0;
  height: 18.5px;
  width: 16px;
}
.is-for-delivery {
  position: relative;
  line-height: 21px;
  display: inline-block;
  min-width: 102px;
}
.checkbox-elements-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-input {
  margin: 0;
  height: 18.5px;
  width: 16px;
}
.over-18-only {
  position: relative;
  line-height: 21px;
  display: inline-block;
  min-width: 91px;
}
.frame-group,
.frame-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-parent {
  gap: var(--gap-base);
  font-size: var(--desktop-button-size);
}
.category {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 81px;
}
.input1 {
  align-self: stretch;
  height: 44px;
  box-shadow: var(--shadow-xs);
  font-family: var(--desktop-detail);
  font-size: var(--desktop-button-size);
  color: var(--default-default-900);
}
.dropdown,
.input-dropdown-base1,
.input-with-label1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-dropdown-base1,
.input-with-label1 {
  align-self: stretch;
}
.dropdown {
  min-width: 320px;
  gap: var(--gap-5xs);
}
.input-field {
  border: 0;
  background-color: transparent;
  height: 68px;
  width: 320px;
  font-family: var(--desktop-detail);
  font-weight: 500;
  font-size: var(--desktop-h4-size);
  color: var(--default-default-900);
}
.category1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 81px;
}
.icons32 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 24px;
}
.category-dropdown-value {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
}
.content5 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 117px;
}
.chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.input-with-label2,
.input2 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.input2 {
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-smi);
  gap: var(--gap-5xs);
}
.input-with-label2 {
  flex-direction: column;
  align-items: flex-start;
}
.input-dropdown-base2 {
  align-self: stretch;
  flex-direction: column;
  font-size: var(--desktop-button-size);
}
.dropdown1,
.head-parent,
.input-dropdown-base2,
.inputs {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.dropdown1 {
  width: 320px;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.head-parent,
.inputs {
  align-self: stretch;
}
.inputs {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 var(--padding-5xs) 0 0;
  box-sizing: border-box;
  gap: 13px 24px;
  /* min-height: 242px; */
  font-size: var(--desktop-h4-size);
  /* width: 100%; */
  flex-grow: 1;
}
.head-parent {
  flex-direction: column;
  padding: 0 0 var(--padding-mini);
  gap: var(--gap-5xl);
  font-size: var(--desktop-h2-size);
}
.add-good-to {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 24px;
  font-weight: 600;
  font-family: inherit;
}
.label12 {
  align-self: stretch;
  width: 320px;
  line-height: 20px;
  display: none;
}
.label12,
.text8 {
  position: relative;
  font-weight: 500;
}
.badge-base {
  border-radius: var(--br-base);
  background-color: var(--warning-50);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-5xs);
}
.badge12 {
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  mix-blend-mode: multiply;
  text-align: center;
  font-size: var(--desktop-cell-text-size);
  color: var(--warning-700);
  font-family: var(--font-poppins);
}
.icons33 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.input3,
.top {
  align-self: stretch;
}
.top {
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.input3 {
  border: 0;
  background-color: transparent;
  height: 44px;
  box-shadow: var(--shadow-xs);
  font-family: var(--desktop-detail);
  font-size: var(--desktop-button-size);
  color: var(--default-default-600);
}
.input-with-label3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.hint-text {
  width: 320px;
  position: relative;
  font-size: var(--desktop-detail-size);
  line-height: 20px;
  color: var(--default-default-600);
  display: none;
}
.input-field-base,
.input-field1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field-base {
  gap: var(--gap-9xs);
}
.input-field1 {
  font-size: var(--desktop-h4-size);
}
.block {
  flex: 1;
  flex-direction: column;
  padding: 0 580px 0 0;
  gap: var(--gap-base);
  max-width: 100%;
}
.block,
.block-wrapper,
.role-child {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.block-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-mini);
  max-width: 100%;
}
.role-child {
  width: 271px;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 30px;
  flex-direction: column;
  padding: var(--padding-7xs) 0 0;
  font-family: var(--desktop-detail);
  font-weight: 600;
  font-size: var(--desktop-h3-size);
  color: var(--default-default-900);
}
.icons34 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-top: -3px;
}
.button-base16,
.role {
  display: flex;
  flex-direction: row;
}
.button-base16 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.role {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-100);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 var(--padding-11xs);
  row-gap: 20px;
}
.search-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.text9 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 128px;
}
.content6 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 832px 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  max-width: 100%;
}
.input-dropdown-base3,
.input-with-label4,
.input4 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.input4 {
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-smi);
}
.input-dropdown-base3,
.input-with-label4 {
  flex-direction: column;
  align-items: flex-start;
}
.input-dropdown-base3 {
  font-size: var(--desktop-button-size);
}
.text10 {
  align-self: stretch;
  width: 70px;
  position: relative;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell8,
.table-header5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header5 {
  flex: 1;
}
.table-header-cell8 {
  height: 44px;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
  padding: var(--padding-sm-5) var(--padding-9xs);
}
.more-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.dropdown2,
.table-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell {
  border-bottom: 1px solid var(--default-default-300);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-5xs) var(--padding-5xl)
    var(--padding-3xs);
}
.more-vertical-icon1 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.dropdown3,
.table-cell1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell1 {
  border-bottom: 1px solid var(--default-default-300);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-5xs) var(--padding-5xl)
    var(--padding-3xs);
}
.more-vertical-icon2 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.dropdown4 {
  flex-direction: column;
  align-items: flex-start;
}
.cells,
.dropdown4,
.row,
.table-cell2 {
  display: flex;
  justify-content: flex-start;
}
.table-cell2 {
  border-bottom: 1px solid var(--default-default-300);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-5xs) var(--padding-5xl)
    var(--padding-3xs);
}
.cells,
.row {
  flex-direction: column;
  align-items: flex-start;
}
.row {
  width: 38px;
}
.table-header-cell9 {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  height: 44px;
  font-family: var(--desktop-detail);
  font-size: var(--desktop-detail-size);
  color: #5c5749;
}
.text11 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 63px;
}
.table-cell3,
.text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell3 {
  align-self: stretch;
  border-bottom: 1px solid var(--default-default-300);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text12 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 63px;
}
.table-cell4,
.text-and-supporting-text1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell4 {
  align-self: stretch;
  border-bottom: 1px solid var(--default-default-300);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text13 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 63px;
}
.text-and-supporting-text2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cells1,
.table-cell5 {
  align-self: stretch;
  justify-content: flex-start;
}
.table-cell5 {
  border-bottom: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.cells1 {
  flex-direction: column;
}
.cells1,
.row1,
.sticky-column {
  display: flex;
  align-items: flex-start;
}
.row1 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  font-size: var(--desktop-cell-text-size);
  color: var(--color-gray-100);
}
.sticky-column {
  width: 223px;
  border-right: 1px solid var(--default-default-300);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
}
.table-header-cell10 {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  height: 44px;
  font-family: var(--desktop-detail);
  font-size: var(--desktop-detail-size);
  color: var(--default-default-600);
}
.row2 {
  width: 201px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.energy-value {
  margin-block-start: 0;
  margin-block-end: 12px;
}
.kcalcal {
  margin: 0;
}
.text14 {
  flex: 1;
  position: relative;
  line-height: 5px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell11,
.table-header6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header6 {
  flex: 1;
}
.table-header-cell11 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  padding: 19.5px var(--padding-9xs) var(--padding-lg);
}
.kcal {
  margin-block-start: 0;
  margin-block-end: 14px;
}
.kj {
  margin: 0;
}
.text15 {
  align-self: stretch;
  position: relative;
  line-height: 7px;
}
.text-and-supporting-text3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell6 {
  align-self: stretch;
  border-bottom: 1px solid var(--default-default-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xl) var(--padding-3xs) var(--padding-xl);
}
.kcal1 {
  margin-block-start: 0;
  margin-block-end: 14px;
}
.kj1 {
  margin: 0;
}
.scrolable-dropdown-inputs-container {
  align-self: stretch;
  position: relative;
  line-height: 7px;
}
.text-and-supporting-text4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell7 {
  align-self: stretch;
  border-bottom: 1px solid var(--default-default-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xl) var(--padding-3xs) var(--padding-xl);
}
.kcal2 {
  margin-block-start: 0;
  margin-block-end: 14px;
}
.kj2 {
  margin: 0;
}
.scrolable-dropdown-inputs-container1 {
  align-self: stretch;
  position: relative;
  line-height: 7px;
}
.text-and-supporting-text5 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.cells2,
.row3,
.table-cell8,
.text-and-supporting-text5 {
  display: flex;
  justify-content: flex-start;
}
.table-cell8 {
  align-self: stretch;
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-3xl) var(--padding-3xs) var(--padding-xl);
}
.cells2,
.row3 {
  flex-direction: column;
  align-items: flex-start;
}
.cells2 {
  align-self: stretch;
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.row3 {
  width: 81px;
  flex-shrink: 0;
  font-size: var(--font-size-2xs);
}
.text16 {
  flex: 1;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell12,
.table-header7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header7 {
  flex: 1;
}
.table-header-cell12 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  padding: var(--padding-sm-5) var(--padding-9xs) var(--padding-xs);
}
.text17 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell9,
.text-and-supporting-text6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell9 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text18 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell10,
.text-and-supporting-text7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell10 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text19 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell11,
.text-and-supporting-text8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell11 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.cells3,
.scrolable-row-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cells3 {
  justify-content: flex-start;
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.scrolable-row-container {
  width: 62px;
  flex-shrink: 0;
  justify-content: center;
}
.fatty {
  margin-block-start: 0;
  margin-block-end: 12px;
}
.acids {
  margin: 0;
}
.text20 {
  flex: 1;
  position: relative;
  line-height: 4px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell13,
.table-header8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header8 {
  flex: 1;
}
.table-header-cell13 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  padding: var(--padding-xl) var(--padding-9xs) var(--padding-lg);
}
.text21 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell12,
.text-and-supporting-text9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell12 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text22 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell13,
.text-and-supporting-text10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell13 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text23 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell14,
.text-and-supporting-text11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell14 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.cells4,
.scrolable-row-container1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cells4 {
  justify-content: flex-start;
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.scrolable-row-container1 {
  width: 62px;
  flex-shrink: 0;
  justify-content: center;
  font-size: var(--font-size-2xs);
}
.carbo- {
  margin-block-start: 0;
  margin-block-end: 12px;
}
.hydrates {
  margin: 0;
}
.text24 {
  flex: 1;
  position: relative;
  line-height: 4px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell14,
.table-header9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header9 {
  flex: 1;
}
.table-header-cell14 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  padding: var(--padding-xl) var(--padding-9xs) var(--padding-lg);
}
.text25 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell15,
.text-and-supporting-text12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell15 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text26 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell16,
.text-and-supporting-text13 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell16 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text27 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell17,
.text-and-supporting-text14 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell17 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.cells5,
.scrolable-row-container2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cells5 {
  justify-content: flex-start;
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.scrolable-row-container2 {
  width: 62px;
  flex-shrink: 0;
  justify-content: center;
  font-size: var(--font-size-2xs);
}
.text28 {
  flex: 1;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell15,
.table-header10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header10 {
  flex: 1;
}
.table-header-cell15 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  padding: var(--padding-sm-5) var(--padding-9xs) var(--padding-xs);
}
.text29 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell18,
.text-and-supporting-text15 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell18 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text30 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell19,
.text-and-supporting-text16 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell19 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text31 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell20,
.text-and-supporting-text17 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell20 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.cells6,
.scrolable-row-container3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cells6 {
  justify-content: flex-start;
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.scrolable-row-container3 {
  width: 62px;
  flex-shrink: 0;
  justify-content: center;
}
.text32 {
  flex: 1;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell16,
.table-header11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header11 {
  flex: 1;
}
.table-header-cell16 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  padding: var(--padding-sm-5) var(--padding-10xs) var(--padding-xs);
}
.text33 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell21,
.text-and-supporting-text18 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell21 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text34 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell22,
.text-and-supporting-text19 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell22 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text35 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell23,
.text-and-supporting-text20 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell23 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.cells7,
.scrolable-row-container4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cells7 {
  justify-content: flex-start;
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.scrolable-row-container4 {
  width: 62px;
  flex-shrink: 0;
  justify-content: center;
}
.text36 {
  flex: 1;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell17,
.table-header12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header12 {
  flex: 1;
}
.table-header-cell17 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  padding: var(--padding-sm-5) var(--padding-9xs) var(--padding-xs);
}
.text37 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell24,
.text-and-supporting-text21 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell24 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text38 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell25,
.text-and-supporting-text22 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell25 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.text39 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 42px;
}
.table-cell26,
.text-and-supporting-text23 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-cell26 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-3xs) var(--padding-5xl);
}
.cells8,
.scrolable-row-container5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cells8 {
  justify-content: flex-start;
  font-size: var(--desktop-cell-text-size);
  color: var(--default-default-900);
}
.scrolable-row-container5 {
  width: 62px;
  flex-shrink: 0;
  justify-content: center;
}
.text40 {
  flex: 1;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header13 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.button,
.table-header-cell18 {
  background-color: var(--default-default-0);
}
.table-header-cell18 {
  align-self: stretch;
  border-bottom: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-sm-5) var(--padding-9xs) var(--padding-xs);
}
.button {
  height: 16px;
  width: 16px;
  position: relative;
  box-shadow: var(--shadow-sm);
  border-radius: 50%;
}
.toggle-base {
  width: 36px;
  height: 20px;
  border-radius: var(--br-xs);
  background-color: var(--primary-primary-500);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-11xs) var(--padding-11xs) var(--padding-11xs)
    var(--padding-lg);
  box-sizing: border-box;
}
.table-cell27,
.toggle {
  display: flex;
  justify-content: flex-start;
}
.toggle {
  height: 20px;
  width: 36px;
  flex-direction: column;
  align-items: flex-start;
}
.table-cell27 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-base) var(--padding-5xl);
}
.button1 {
  height: 16px;
  width: 16px;
  position: relative;
  box-shadow: var(--shadow-sm);
  border-radius: 50%;
  background-color: var(--default-default-0);
}
.toggle-base1 {
  width: 36px;
  height: 20px;
  border-radius: var(--br-xs);
  background-color: var(--primary-primary-500);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-11xs) var(--padding-11xs) var(--padding-11xs)
    var(--padding-lg);
  box-sizing: border-box;
}
.table-cell28,
.toggle1 {
  display: flex;
  justify-content: flex-start;
}
.toggle1 {
  height: 20px;
  width: 36px;
  flex-direction: column;
  align-items: flex-start;
}
.table-cell28 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-base) var(--padding-5xl);
}
.button2 {
  height: 16px;
  width: 16px;
  position: relative;
  box-shadow: var(--shadow-sm);
  border-radius: 50%;
  background-color: var(--default-default-0);
}
.toggle-base2 {
  width: 36px;
  height: 20px;
  border-radius: var(--br-xs);
  background-color: var(--primary-primary-500);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-11xs) var(--padding-11xs) var(--padding-11xs)
    var(--padding-lg);
  box-sizing: border-box;
}
.toggle2 {
  height: 20px;
  width: 36px;
  flex-direction: column;
  align-items: flex-start;
}
.cells9,
.row4,
.table-cell29,
.toggle2 {
  display: flex;
  justify-content: flex-start;
}
.table-cell29 {
  border-bottom: 1px solid var(--default-default-100);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-7xl) var(--padding-base) var(--padding-5xl);
}
.cells9,
.row4 {
  flex-direction: column;
  align-items: flex-start;
}
.row4 {
  width: 68px;
  flex-shrink: 0;
}
.text41 {
  flex: 1;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header-cell19,
.table-header14 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.table-header14 {
  flex: 1;
}
.table-header-cell19 {
  align-self: stretch;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  padding: var(--padding-sm-5) var(--padding-9xs) var(--padding-xs);
}
.product-icon,
.x-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.x-icon {
  height: 12px;
  width: 12px;
}
.badge-base1,
.badge13 {
  display: flex;
  flex-direction: row;
}
.badge-base1 {
  border-radius: var(--br-base);
  background-color: var(--warning-50);
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-7xs) var(--padding-11xs)
    var(--padding-5xs);
  gap: var(--gap-9xs);
}
.badge13 {
  align-items: flex-start;
  justify-content: flex-start;
  mix-blend-mode: multiply;
}
.product-icon1,
.x {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.x {
  margin: 0;
  height: 12px;
  width: 12px;
}
.badge-base2,
.badge14 {
  display: flex;
  flex-direction: row;
}
.badge-base2 {
  border-radius: var(--br-base);
  background-color: var(--warning-50);
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-7xs) var(--padding-11xs)
    var(--padding-5xs);
  gap: var(--gap-9xs);
}
.badge14 {
  align-items: flex-start;
  justify-content: flex-start;
  mix-blend-mode: multiply;
}
.product-icon2,
.x-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.x-icon1 {
  height: 12px;
  width: 12px;
}
.badge-base3 {
  border-radius: var(--br-base);
  background-color: var(--warning-50);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-7xs) var(--padding-11xs)
    var(--padding-5xs);
  gap: var(--gap-9xs);
}
.badge15,
.content7 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.badge15 {
  align-items: flex-start;
  mix-blend-mode: multiply;
}
.content7 {
  flex: 1;
  align-items: center;
  padding: 0;
  gap: var(--gap-11xs);
}
.chevron-down-icon1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}
.input5 {
  align-self: stretch;
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-smi);
  gap: var(--gap-5xs);
}
.input-with-label5 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.input-dropdown-base4,
.input-with-label5,
.table-cell30 {
  display: flex;
  justify-content: flex-start;
}
.input-dropdown-base4 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-9xs);
}
.table-cell30 {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-sm) var(--padding-3xs) var(--padding-xs);
}
.cells10,
.scrolable-row-container6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.cells10 {
  align-self: stretch;
}
.scrolable-row-container6 {
  width: 184px;
  flex-shrink: 0;
}
.text42 {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header15 {
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.cells11,
.scrolable-row-container7,
.table-header-cell20,
.table-header15 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.table-header-cell20 {
  height: 44px;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-sm-5) var(--padding-9xs);
}
.cells11,
.scrolable-row-container7 {
  flex-direction: column;
  align-items: flex-start;
}
.cells11 {
  flex: 1;
}
.scrolable-row-container7 {
  width: 184px;
  flex-shrink: 0;
}
.text43 {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-header16 {
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.cells12,
.scrolable-row-container8,
.table-header-cell21,
.table-header16 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.table-header-cell21 {
  height: 44px;
  background-color: var(--default-default-0);
  border-bottom: 1px solid var(--default-default-300);
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-sm-5) var(--padding-9xs);
}
.cells12,
.scrolable-row-container8 {
  flex-direction: column;
  align-items: flex-start;
}
.cells12 {
  flex: 1;
}
.scrolable-row-container8 {
  width: 184px;
  flex-shrink: 0;
  z-index: 1;
}
.scrolable,
.table {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.scrolable {
  height: 260px;
  width: 849px;
  overflow-x: auto;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.table {
  width: 1497px;
  background-color: var(--default-default-0);
  align-items: center;
  justify-content: flex-end;
  max-width: 140%;
  row-gap: 20px;
}
.slider-child {
  height: 15px;
  width: 216px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
}
.slider,
.table-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
}
.slider {
  width: 1493px;
  border-radius: var(--br-5xs);
  background-color: #f7f7f7;
  flex-direction: row;
  max-width: 140%;
}
.table-wrapper {
  width: 1072px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
  max-width: 106%;
}
.values-for {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 67px;
}
.checkbox-base,
.checkbox-base1,
.checkbox-base2 {
  cursor: pointer;
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}
.checkbox-base2 {
  background-color: var(--primary-primary-50);
  border: 1px solid var(--primary-primary-500);
}
.checkbox-base-parent,
.portion-checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.checkbox-base-parent {
  gap: 19px;
}
.portion-checkboxes {
  padding: 1.5px 0 0;
}
.for-a-brute,
.for-a-net,
.g {
  position: relative;
}
.for-a-net,
.g {
  display: inline-block;
  min-width: 123px;
}
.g {
  min-width: 42px;
}
.portion-checkboxes-parent,
.portion-options,
.values-for-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.portion-checkboxes-parent,
.values-for-label {
  flex-direction: row;
  gap: var(--gap-5xs);
  font-size: var(--desktop-button-size);
}
.values-for-label {
  flex-direction: column;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--desktop-detail-size);
}
.text44,
.values-per-100g1 {
  position: relative;
  font-weight: 500;
}
.values-per-100g1 {
  display: inline-block;
  min-width: 93px;
}
.text44 {
  line-height: 20px;
}
.badge-base4,
.badge16 {
  display: flex;
  flex-direction: row;
}
.badge-base4 {
  border-radius: var(--br-base);
  background-color: var(--warning-50);
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-xs);
  white-space: nowrap;
}
.badge16 {
  align-items: flex-start;
  justify-content: flex-start;
  mix-blend-mode: multiply;
}
.text45 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 56px;
}
.badge-base5,
.badge17 {
  display: flex;
  flex-direction: row;
}
.badge-base5 {
  border-radius: var(--br-base);
  background-color: var(--warning-50);
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-xs);
  white-space: nowrap;
}
.badge17 {
  align-items: flex-start;
  justify-content: flex-start;
  mix-blend-mode: multiply;
}
.text46 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.badge-base6 {
  border-radius: var(--br-base);
  background-color: var(--warning-50);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-xs);
  white-space: nowrap;
}
.badge18 {
  flex: 1;
  flex-direction: row;
  mix-blend-mode: multiply;
  min-width: 134px;
}
.badge18,
.values-per-100g,
.values-per-gram-badges {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.values-per-gram-badges {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--desktop-detail-size);
  color: var(--warning-700);
}
.values-per-100g {
  flex: 1;
  flex-direction: column;
  padding: 0 0 var(--padding-44xl);
  box-sizing: border-box;
  gap: var(--gap-base);
  min-width: 321px;
  max-width: 100%;
  font-size: var(--desktop-cell-text-size);
}
.checkbox-base3 {
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-9xs);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}
.checkbox-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-86xl) 0 0;
}
.use-for-other {
  position: relative;
}
.checkbox-options1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-85xl) var(--padding-5xs) 0 0;
}
.checkbox-base4 {
  margin: 0;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: var(--br-9xs);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}
.checkbox-options2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-86xl) 0 0;
}
.exclude-from-menu {
  position: relative;
}
.checkbox-options3,
.values-for-label-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-85xl) 0 0;
}
.values-for-label-parent {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 var(--padding-11xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  max-width: 100%;
  font-size: var(--font-size-mini);
  color: var(--default-default-900);
}
.text47 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button-base17 {
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  border: 1px solid var(--default-default-300);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-smi);
}
.text48 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button-base18,
.buttons3 {
  flex-direction: row;
  align-items: center;
}
.button-base18 {
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  background-color: var(--primary-primary-500);
  border: 1px solid var(--primary-primary-500);
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-smi);
  white-space: nowrap;
}
.buttons3 {
  align-self: stretch;
  display: none;
  justify-content: flex-end;
  gap: var(--gap-base);
  color: var(--default-default-900);
}
.role-parent,
.window {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.window {
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  overflow: hidden;
  padding: var(--padding-xl) 0;
  gap: 44px;
}
.role-parent {
  padding: 0 0 var(--padding-mini);
  gap: var(--gap-5xs);
  font-size: var(--desktop-detail-size);
  color: var(--default-default-600);
}
.product-price {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 24px;
  font-weight: 600;
  font-family: inherit;
}
.inputs1 {
  width: 70%;
    border-right: 2px solid var(--default-default-300);
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 52px 0 0;
    gap: 16px 12px;
}
.average-purchase-cost,
.ron {
  position: relative;
  font-weight: 600;
}
.average-purchase-cost {
  margin: 0;
  font-size: inherit;
  line-height: 24px;
  font-family: inherit;
}
.ron {
  font-size: var(--desktop-h2-size);
  line-height: 34px;
  color: var(--default-default-900);
}
.total-cost1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.gross-profit,
.ron1 {
  position: relative;
  font-weight: 600;
}
.gross-profit {
  margin: 0;
  font-size: inherit;
  line-height: 24px;
  font-family: inherit;
  display: inline-block;
  min-width: 115px;
}
.ron1 {
  font-size: var(--desktop-h2-size);
  line-height: 34px;
  color: var(--m3-sys-light-error);
}
.total,
.total-cost2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.total-cost2 {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  gap: var(--gap-5xs);
}
.total {
  flex: 1;
  justify-content: center;
  gap: var(--gap-5xl);
  min-width: 430px;
}

.custom-quill-editor {
  height: 300px;  /* Adjust the height as needed */
}

.custom-quill-editor .ql-container {
  height: 200px; /* Ensure the container takes full height */
}
.custom-quill-editor .ql-container .ql-editor  {
  height: 200px; /* Ensure the container takes full height */
}

.custom-quill-editor .ql-editor {
  min-height: 200px; /* Set the minimum height for the editor area */
}

.total-cost {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
}
.icons35 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.text49 {
  position: relative;
  font-size: var(--desktop-detail-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
}
.button-base19 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-sm);
  background-color: transparent;
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.totalbutton {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-5xs);
  color: var(--default-default-600);
}
.block1,
.product-price-block,
.totalbutton {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.block1 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
}
.product-price-block {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-mini);
  box-sizing: border-box;
}
.short-description-block {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  height: 83px;
  font-family: var(--desktop-detail);
  font-weight: 500;
  font-size: var(--desktop-h4-size);
  color: var(--default-default-900);
}
.short-description,
.text50 {
  position: relative;
  font-weight: 500;
}
.short-description {
  align-self: stretch;
  line-height: 20px;
}
.text50 {
  line-height: 24px;
  display: inline-block;
  min-width: 94px;
}
.content8 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.chevron-down-icon2 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.input6 {
  align-self: stretch;
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-smi);
  gap: var(--gap-5xs);
}
.input-dropdown-base5,
.input-with-label6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-with-label6 {
  align-self: stretch;
}
.input-dropdown-base5 {
  width: 240px;
}
.text51 {
  position: relative;
  font-size: var(--desktop-detail-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--desktop-detail);
  color: var(--default-default-900);
  text-align: left;
}
.button-base20,
.clear-short-description-button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-base20 {
  cursor: pointer;
  border: 1px solid var(--default-default-300);
  padding: var(--padding-7xs) var(--padding-smi);
  background-color: transparent;
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-direction: row;
  white-space: nowrap;
}
.button-base20:hover {
  background-color: rgba(153, 150, 150, 0.09);
  border: 1px solid #999696;
  box-sizing: border-box;
}
.clear-short-description-button {
  flex-direction: column;
  padding: var(--padding-11xs) 0 0;
}
.short-description-parent,
.top1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.top1 {
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  font-size: var(--desktop-button-size);
  font-family: var(--font-poppins);
}
.short-description-parent {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  max-width: 100%;
  font-size: var(--desktop-h4-size);
}
.description,
.text52 {
  position: relative;
  font-weight: 500;
}
.description {
  align-self: stretch;
  line-height: 20px;
}
.text52 {
  line-height: 24px;
  display: inline-block;
  min-width: 94px;
}
.content9 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.chevron-down-icon3 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.input7 {
  align-self: stretch;
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-smi);
  gap: var(--gap-5xs);
}
.input-dropdown-base6,
.input-with-label7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-with-label7 {
  align-self: stretch;
}
.input-dropdown-base6 {
  flex: 1;
  min-width: 156px;
}
.toolbar-icons,
.toolbar-icons1,
.toolbar-icons2 {
  height: 24px;
  width: 24px;
  position: relative;
  min-height: 24px;
}
.left-toolbar,
.left-toolbar-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.left-toolbar {
  flex-direction: row;
  gap: var(--gap-base);
}
.left-toolbar-wrapper {
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-base) 0 0;
}
.toolbar-icons3 {
  width: 24px;
  height: 24px;
  position: relative;
}
.single-toolbar-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-base) 0 0;
}
.toolbar-icons4,
.toolbar-icons5 {
  height: 24px;
  width: 24px;
  position: relative;
  min-height: 24px;
}
.right-toolbar,
.right-toolbar-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.right-toolbar {
  flex-direction: row;
  gap: var(--gap-base);
}
.right-toolbar-wrapper {
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
}
.description-parent,
.input-dropdown-base-parent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.input-dropdown-base-parent {
  width: 512px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap-base);
  font-size: var(--desktop-button-size);
  font-family: var(--font-poppins);
}
.description-parent {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--desktop-h4-size);
}
.label13,
.text53 {
  position: relative;
  line-height: 20px;
}
.label13 {
  width: 77px;
  font-weight: 500;
  display: none;
}
.text53 {
  height: 473px;
  flex: 1;
  display: inline-block;
  max-width: 100%;
}
.input-with-label8,
.input8 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.input8 {
  box-shadow: var(--shadow-xs);
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-smi);
  font-size: var(--desktop-button-size);
  color: var(--default-default-600);
  font-family: var(--body);
}
.input-with-label8 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-7xs);
}
.hint-text1 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: var(--gray-500);
  display: none;
}
.textarea-input-field-base {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-7xs);
}
.textarea-input-field,
.textarea-input-field-base,
.textarea-input-field-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.textarea-input-field {
  flex: 1;
  flex-direction: column;
}
.textarea-input-field-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-mini);
  box-sizing: border-box;
  font-size: var(--desktop-detail-size);
  color: var(--gray-700);
}
.button-base21 {
  height: 44px;
  width: 347px;
  box-shadow: var(--shadow-xs);
  max-width: 100%;
}
.data-wrapper,
.data1,
.save-description-button {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.save-description-button {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
}
.data-wrapper,
.data1 {
  justify-content: flex-start;
  box-sizing: border-box;
}
.data1 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--default-default-0);
  border: 1px solid var(--default-default-300);
  flex-direction: column;
  padding: var(--padding-lg) 31px;
  gap: var(--gap-base);
}
.data-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-5xl) 0 var(--padding-xl);
  text-align: left;
  font-size: var(--desktop-h3-size);
  color: var(--default-default-900);
}
.top-bar-group {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-base);
  max-width: calc(100% - 316px);
  text-align: center;
  font-size: var(--desktop-detail-size);
  color: var(--default-default-600);
  font-family: var(--desktop-detail);
}
.configuration-add-product,
.right-panel-parent,
.top-bar-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.right-panel-parent {
  align-self: stretch;
  flex-direction: row;
  max-width: 100%;
}
.configuration-add-product {
  width: 100%;
  position: relative;
  background-color: var(--bg);
  overflow: hidden;
  flex-direction: column;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .table {
    flex-wrap: wrap;
  }
  .inputs1 {
    padding-right: var(--padding-7xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .add-product {
    font-size: var(--font-size-5xl);
    line-height: 27px;
  }
  .block,
  .content6,
  .inputs1 {
    padding-right: 290px;
    box-sizing: border-box;
  }
  .content6,
  .inputs1 {
    padding-right: 416px;
  }
  .inputs1 {
    flex: 1;
    padding-right: 0;
    min-width: 100%;
  }
  .ron,
  .ron1 {
    font-size: var(--font-size-5xl);
    line-height: 27px;
  }
  .total-cost {
    flex-wrap: wrap;
  }
  .top-bar-group {
    max-width: 100%;
  }
  .right-panel-parent {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .head {
    flex-wrap: wrap;
  }
  .block,
  .content6 {
    padding-right: 145px;
    box-sizing: border-box;
  }
  .content6 {
    padding-right: 208px;
  }
  .values-per-gram-badges {
    flex-wrap: wrap;
  }
  .window {
    gap: 22px;
  }
  .top1 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .add-product {
    font-size: var(--desktop-h4-size);
    line-height: 20px;
  }
  .predefined-fields {
    font-size: var(--desktop-button-size);
    line-height: 19px;
  }
  .input2 {
    flex-wrap: wrap;
  }
  .add-good-to {
    font-size: var(--desktop-button-size);
    line-height: 19px;
  }
  .block {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .buttons3,
  .role {
    flex-wrap: wrap;
  }
  .average-purchase-cost,
  .product-price {
    font-size: var(--desktop-button-size);
    line-height: 19px;
  }
  .ron {
    font-size: var(--desktop-h4-size);
    line-height: 20px;
  }
  .gross-profit {
    font-size: var(--desktop-button-size);
    line-height: 19px;
  }
  .ron1 {
    font-size: var(--desktop-h4-size);
    line-height: 20px;
  }
  .button-base21 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
