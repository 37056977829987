.graph-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.graph-title h5 {
    color: rgba(0, 0, 0, 1);
    font-size: 17px;
    font-weight: 500;
}

.graph-title p {
    color: rgba(138, 138, 138, 1);
    font-size: 14px;
    font-weight: 500;
}

.graph-title span {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 14px;
}

.graph-box {
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
}


@media (max-width:1410px) {}