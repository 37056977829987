.data-table-detail .MuiDataGrid-row .view-detail-btn {
    border: 1px solid #FFB800;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    border-radius: 10px;
    color: #000;
    text-transform: capitalize;
    font-weight: 500;
    padding: 5px 37px;
}

.view-detail-btn img {
    margin-left: 10px;
}

.data-table-detail .MuiDataGrid-root {
    border-top-left-radius: 10px;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
}

.data-table-detail .MuiDataGrid-columnHeadersInner {
    background-color: #FFF3D3 !important;
}

.data-table-detail .MuiDataGrid-columnHeaderTitle {
    color: #000;
    font-weight: 600 !important;
}

.data-table-detail .MuiDataGrid-cellContent {
    color: #403C3C;
    font-weight: 600 !important;
}

.data-table-detail .MuiDataGrid-cell:focus {
    outline: unset !important;
}

/* .MuiDataGrid-columnHeader {
    max-width: max-content !important;
    min-width: fit-content !important;
} */

.MuiDataGrid-columnHeaderTitle {
    overflow: unset;
}

.data-table-detail .MuiDataGrid-columnHeader:focus {
    outline: unset !important;
}

.data-table-detail .MuiDataGrid-cell:focus-within {
    outline: unset !important;
}

.data-table-detail .MuiDataGrid-virtualScroller {
    overflow-x: auto !important;
}

.data-table-detail .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.data-table-detail .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    background: #FFF3D3;
}

.data-table-detail .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background: #ffb800;
}

.data-table-detail .MuiDataGrid-columnHeaders {
    max-height: inherit !important;
}

.MuiDataGrid-main {
    overflow-x: auto !important;
}

.MuiDataGrid-main::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.MuiDataGrid-main::-webkit-scrollbar-track {
    background: #FFF3D3;
}

.MuiDataGrid-main::-webkit-scrollbar-thumb {
    background: #ffb800;
}