@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter', sans-serif !important;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #888;
}


body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --desktop-detail: Inter;
  --font-poppins: Poppins;
  --body: Nunito;

  /* font sizes */
  --desktop-detail-size: 14px;
  --desktop-cell-text-size: 12px;
  --desktop-h4-size: 18px;
  --desktop-button-size: 16px;
  --desktop-h3-size: 20px;
  --desktop-h2-size: 30px;
  --font-size-5xl: 24px;
  --font-size-mini: 15px;
  --font-size-2xs: 11px;

  /* Colors */
  --bg: #fffefc;
  --default-default-400: #7d7a7a;
  --default-default-900: #222327;
  --color-gray-100: #281a10;
  --default-default-0: #fff;
  --default-default-300: #cbcac8;
  --gray-700: #344154;
  --primary-primary-900: #3b3b3b;
  --default-default-600: #3c3a35;
  --primary-primary-700: #dea104;
  --default-default-100: #f0f0f0;
  --color-whitesmoke-100: #eceef1;
  --gray-200: #eaecf0;
  --warning-50: #fffaeb;
  --warning-700: #b64708;
  --primary-primary-500: #ffb800;
  --color-darkgoldenrod: #cc8500;
  --m3-sys-light-error: #b3261e;
  --color-gainsboro: #d9d9d9;
  --primary-primary-50: #fffaf5;
  --error: #fd4d4d;
  --color-navajowhite: #ffdea1;
  --gray-500: #667085;

  /* Gaps */
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-xl: 20px;
  --gap-3xs: 10px;
  --gap-9xs: 4px;
  --gap-8xs: 5px;
  --gap-5xl: 24px;
  --gap-7xs: 6px;
  --gap-7xl: 26px;
  --gap-11xs: 2px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-5xl: 24px;
  --padding-lg: 18px;
  --padding-lgi: 19px;
  --padding-7xs: 6px;
  --padding-6xs: 7px;
  --padding-5xs: 8px;
  --padding-7xl: 26px;
  --padding-3xs: 10px;
  --padding-6xl: 25px;
  --padding-4xl: 23px;
  --padding-11xs: 2px;
  --padding-base: 16px;
  --padding-sm-5: 13.5px;
  --padding-xs: 12px;
  --padding-smi: 13px;
  --padding-24xl: 43px;
  --padding-sm: 14px;
  --padding-3xl: 22px;
  --padding-25xl: 44px;
  --padding-540xl: 559px;
  --padding-344xl: 363px;
  --padding-217xl: 236px;
  --padding-12xs: 1px;
  --padding-mid: 17px;
  --padding-134xl: 153px;
  --padding-21xl: 40px;
  --padding-109xl: 128px;
  --padding-84xl: 103px;
  --padding-48xl: 67px;
  --padding-4xs: 9px;
  --padding-23xl: 42px;
  --padding-73xl: 92px;
  --padding-44xl: 63px;
  --padding-mini: 15px;
  --padding-85xl: 104px;
  --padding-86xl: 105px;
  --padding-9xs: 4px;
  --padding-10xs: 3px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-base: 16px;
  --br-81xl: 100px;
  --br-11xs: 2px;
  --br-181xl: 200px;
  --br-9xs: 4px;
  --br-7xs: 6px;
  --br-xs: 12px;

  /* Effects */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-sm: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
}