.star-rating {
  display: inline-flex;
  cursor: pointer;
}

.star {
  font-size: 24px;
  color: #ddd; /* Light grey color for empty stars */
  transition: color 0.2s ease;
}

.star.filled {
  color: #f39c12; /* Gold color for filled stars */
}

.star.half-filled {
  background: linear-gradient(90deg, #f39c12 50%, lightgray 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


