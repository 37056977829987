.location-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.location-detail input {
    padding: 10px 14px;
    padding-left: 0px;
    font-weight: 500;
    font-size: 14px;
}

.location-detail input::placeholder {
    color: #000;
    font-weight: 500;
    opacity: 1;
    font-size: 14px;
}

.location-detail .MuiInputBase-root {
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    background-color: #fff;
    border-radius: 10px !important;
}

.location-detail .refresh-btn {
    background-color: #fff !important;
    border: 1px solid #FFB800;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 8px 35px;
}

.location-detail .add-partner {
    background-color: #FFB800 !important;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px 35px;
    margin-left: 10px;
}

.food-box {
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.5s;
    cursor: pointer;
}

.food-box:hover {
    transform: scale(1.1);
}

.food-box img {
    width: 100%;
}

.food-detail {
    padding: 10px;
}

.food-detail h5 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.food-detail p {
    color: #403C3C;
    font-size: 14px;
    font-weight: 600;
}

.food-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.food-box .MuiSwitch-track {
    background-color: #B7B7B7 !important;
    opacity: 1 !important;
}

.food-box .MuiSwitch-switchBase {
    color: #4F4F4F !important;
}

.food-box .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #FFF3D3 !important;
}

.food-box .MuiSwitch-switchBase.Mui-checked {
    color: #FFB800 !important;
}

.show-more-btn {
    text-align: center;
    margin: 25px 0px;
}

.show-more-btn button {
    border: 1px solid #FFB800;
    border-radius: 5px;
    font-weight: 500;
    padding: 5px 35px;
    color: #000;
    text-transform: capitalize;
}

.partner-title .back-btn {
    background-color: #FFB800 !important;
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
}

.partner-title .back-btn svg {
    font-size: 20px;
    margin-right: 4px;
}

.location-tab {
    /* border: 1px solid #C7CCD1B2; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 50px;
    margin-bottom: 22px;
}

.location-img {
    position: relative;
    margin-top: 15px;
}

.location-img img:first-child {
    width: 100%;
    min-height: 140px;
    object-fit: cover;
    border-radius: 10px;
}

.location-img button {
    text-transform: capitalize;
    color: #000;
    background-color: #FFFFFF !important;
    border: 2px solid #FFB800;
    padding: 5px 15px;
    position: absolute;
    top: 30px;
    right: 30px;
    border-radius: 10px;
}

.location-cover-main {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    top: 80px;
}

.location-cover-wrap {
    max-width: 100px;
    width: 100%;
    height: 100px;
    border-radius: 100%;
    border: 5px solid #FFB800;
    position: relative;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.location-cover-wrap img {
    width: 100%;
    height: 100%;
    min-height: unset !important;
    object-fit: cover;
}

.edit-icon-box {
    width: 35px;
    height: 35px;
    text-align: center;
    background-color: #fff;
    border: 3px solid #FFB800;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: -15px;
    text-align: center;
    cursor: pointer;
}

.edit-icon-box svg {
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.location-filled h5 {
    color: #000;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 25px;
}

.location-detail-wrap {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 20px;
    margin-top: 60px;
}

.location-filled-inner {
    margin-bottom: 15px;
}

.location-filled-inner label {
    display: block;
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.location-filled-inner input {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 10px 14px;
    color: #4F4F4F;
    font-weight: 500;
    font-size: 14px;
}


.location-filled-inner input::placeholder {
    color: #4F4F4F;
    opacity: 1;
}

.location-filled-inner .MuiFormControl-root {
    width: 100%;
}

.location-filled-inner .MuiInputBase-root {
    box-shadow: unset;
}

.location-filled-inner fieldset {
    display: none;
}

.location-filled-inner svg {
    color: #8A8A8A;
    font-size: 15px;
}

.payment-accept-detail .MuiSwitch-track {
    background-color: #B7B7B7 !important;
    opacity: 1 !important;
}

.payment-accept-detail .MuiSwitch-switchBase {
    color: #4F4F4F !important;
}

.payment-accept-detail .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #FFF3D3 !important;
}

.payment-accept-detail .MuiSwitch-switchBase.Mui-checked {
    color: #FFB800 !important;
}

.payment-accept-detail .MuiTypography-root {
    color: #000;
    font-weight: 600;
}

.payment-accept-detail {
    margin-top: 50px !important;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
}

.day-schedule p {
    color: #000;
    font-weight: 600;
    font-size: 15px;
}

.day-schedule p:first-child {
    max-width: 90px;
    width: 100%;
}

.day-schedule-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.day-schedule .hours-title {
    margin-bottom: 25px;
}

.day-schedule-inner .MuiSwitch-track {
    background-color: #B7B7B7 !important;
    opacity: 1 !important;
}

.day-schedule-inner .MuiSwitch-switchBase {
    color: #4F4F4F !important;
}

.day-schedule-inner .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #FFF3D3 !important;
}

.day-schedule-inner .MuiSwitch-switchBase.Mui-checked {
    color: #FFB800 !important;
}

.location-detail-btn {
    margin-left: auto;
    text-align: end;
}

.location-detail-btn .cancel-btn {
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    background-color: #fff;
    border: 1px solid #FFB800;
    border-radius: 5px;
    color: #000;
    text-transform: capitalize;
    padding: 7px 25px;
    min-width: 150px;
    margin-right: 7px;
    font-weight: 500;
}

.location-detail-btn .submit-btn {
    border-radius: 5px;
    background-color: #FFB800 !important;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    color: #000;
    text-transform: capitalize;
    padding: 8px 25px;
    min-width: 150px;
    font-weight: 500;
}

.day-schedule-inner input {
    max-width: 60px;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    padding: 5px;
    text-align: center;
    color: #4F4F4F;
    font-weight: 500;
    outline: unset;
}

.menu-option-detail {
    background-color: #FFF;
    filter: drop-shadow(3px 5px 40px rgba(205, 205, 205, 0.45));
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-top: 25px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
}

.menu-option-detail .MuiFormControl-root {
    max-width: 100px;
    width: 100%;
}

.menu-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.menu-list ul {
    display: flex;
}

.menu-list ul li {
    cursor: pointer;
    font-size: 14px;
}

.menu-list ul li svg {
    margin-right: 5px;
    width: 20px;
}

.menu-list ul li:nth-child(1) {
    color: #000;
    font-weight: 500;
}

.menu-list ul li:nth-child(2) {
    color: #0094FF;
    font-weight: 500;
}

.menu-list ul li:nth-child(3) {
    color: #F90000;
    font-weight: 500;
}

.menu-option-detail fieldset {
    display: none;
}

.menu-list .sync-btn {
    background-color: #02AB58 !important;
    color: #fff;
    border-radius: 0px;
    font-weight: 500;
    padding: 8px 25px;
}

.menu-list .sync-btn svg {
    margin-right: 8px;
}

.categories-list {
    background-color: #FFF9E9;
    padding: 30px;
    padding-top: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.categories-list p {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 25px 0px;
}

.categories-box {
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    padding: 15px;
    height: 225px;
    width: 100%;
    text-align: center;
    color: #0094FF;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    transition: all 0.5s;
}

.categories-box:hover {
    transform: scale(1.02);
}

.categories-box p {
    font-size: 16px;
    padding: 0px;
}

.categories-box-active {
    border-color: #0094FF;
}

.categories-box-active p {
    color: #0094FF;
    font-weight: 500;
}

.menu-tab .location-detail-btn {
    margin-top: 30px;
    padding-right: 30px;
}

.premium-modal {
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
    background-color: #fff;
    max-width: 450px;
    width: 100%;
    border-radius: 10px;
    padding: 30px 25px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.categories-list-main {
    position: relative;
}

.categories-list-main::before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.76);
    backdrop-filter: blur(8.5px);
    position: absolute;
}

.premium-modal h5 {
    color: #000;
    font-size: 25px;
    font-weight: 500;
}

.premium-modal p {
    color: #4F4F4F;
    font-size: 16px;
    font-weight: 400;
    margin: 15px 0px;
}

.premium-modal .premium-btn {
    background-color: #FFB800 !important;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px 35px;
    width: 100%;
}

.premium-modal .premium-btn img {
    margin-right: 10px;
}

.rating-tab {
    border: 1px solid #C7CCD1B2;
    border-radius: 10px;
    padding: 15px;
    position: relative;
}

.configuration-tab {
    display: flex;
    gap: 20px;
}

.configuration-tab .add-room-btn {
    background-color: #fff;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    border: 1px solid #FFB800;
    border-radius: 10px;
    color: #000;
    text-transform: capitalize;
    width: 100%;
    height: 45px;
    margin-bottom: 12px;
}

.add-table-detail {
    border: 1px solid #C7CCD1B2;
    border-radius: 10px;
    padding: 20px 15px;
}

.configuration-tab .add-table-btn {
    border: 1px solid #FFB800;
    color: #000;
    text-transform: capitalize;
    border-radius: 5px;
    padding: 10px 15px;
}

.add-room-section {
    max-width: 310px;
    width: 100%;
}

.room-tab-section {
    width: 100%;
}

.table-btn {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.add-table-filled input {
    padding: 10px 14px !important;
    border: 1px solid #D9D9D9;
    background-color: #fff;
    border-radius: 10px;
}

.add-table-filled {
    margin-bottom: 20px;
}

.add-table-filled fieldset {
    display: none;
}

.add-table-filled label {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.add-table-filled .MuiFormControl-root {
    width: 100%;
}

.rotate-btn {
    max-width: 90px;
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.add-table-detail .download-btn {
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    background-color: #FFB800 !important;
    color: #000;
    font-weight: 600;
    display: block;
    text-transform: capitalize;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.add-table-detail .remove-btn {
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    background-color: #F90000 !important;
    color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
}

.room-tab-section {
    border: 1px solid #C7CCD1B2;
    border-radius: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.room-tab {
    padding-left: 50px;
    border-bottom: 1px solid #C7CCD1B2;
}

.room-tab-detail {
    background-color: #FFFFFF;
    height: 485px;
    position: relative;
    overflow: auto;
}

.table-box {
    width: 50%;
    position: relative;
}

.table-box .first-room {
    background-color: #fff;
    max-width: 150px;
    width: 100%;
    height: 70px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 3px dashed transparent;
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
    transition: all 0.5s;
    cursor: pointer;
}

.table-box .first-room:hover {
    background-color: #FFF3D3;
    border: 3px dashed #FFB800;
}

.table-box .first-room p {
    font-size: 18px;
    color: #00000047;
    font-weight: 500;
}

.table-box .first-room:nth-child(1) {
    position: absolute;
    top: 30px;
    left: 50px;
}

.table-box .first-room:nth-child(2) {
    max-width: 200px;
    top: 250px;
    position: absolute;
    left: 70px;
}

.table-box .first-room:nth-child(3) {
    max-width: 100px;
    display: block;
    height: auto;
    text-align: center;
    padding: 5px;
    top: 120px;
    left: 220px;
    position: absolute;
}
.rotate90 {
    transform: rotate(90deg);
}

.total-table {
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45);
    background-color: #fff;
    max-width: 220px;
    width: 100%;
    height: 400px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);

}

.total-table p {
    font-size: 18px;
    color: #00000047;
    font-weight: 500;
}

.table-border {
    height: 400px;
    border: 5px solid #ffb800;
    width: 3px;
    border-radius: 10px;
    position: absolute;
    right: 270px;
    top: 0px;
}

/* Modal Css  */
.user-edit-modal p {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.user-edit-modal span {
    color: #403C3C;
    font-weight: 400;
    margin-top: 5px;
    display: block;
    font-size: 14px;
}

.modifier-accordion .MuiAccordionSummary-content {
    margin: 12px 0px !important;
}


.user-edit-modal .modal-btn {
    justify-content: center;
}

.website-image-box {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.website-image-box svg {
    color: #403C3C;
    font-size: 100px;
}

.modal-btn {
    margin: 25px 0px;
}



.website-image-box [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}

.website-image-box [type="file"]+label {
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Rubik', sans-serif;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
}

.warning-icon-box {
    width: 60px;
    height: 60px;
    background-color: #F900002E;
    border-radius: 100%;
    text-align: center;
    line-height: 80px;
}

.warning-icon-box svg {
    font-size: 35px;
    color: #F90000;
}

.warning-icon {
    display: flex;
    align-items: center;
    gap: 10px;
}

.close-icon {
    margin-left: auto;
    cursor: pointer;
}

.delete-modal .cancel-btn {
    min-width: 180px;
}

.delete-modal .submit-btn {
    min-width: 180px;
    background-color: #F90000 !important;
    color: #fff;
}

.delete-modal .modal-btn {
    justify-content: end;
}
.cropper-modals{
    max-width: 800px;
    width: 100% !important;
    height: auto !important;
    opacity: 1 !important;
}
.add-category-modal {
    max-width: 1000px;
    padding-right: 0px;
}

.add-product-modal,
.add-new-modifier-modal {
    height: auto;
    padding-right: 25px;
}

.add-category-modal-inner,
.review-card-box {
    padding-right: 25px;
}

.user-review-modal {
    padding-right: 0px;
}

.add-category-modal-inner::-webkit-scrollbar,
.add-new-modifier-modal::-webkit-scrollbar,
.nutritional-modal::-webkit-scrollbar,
.review-card-box::-webkit-scrollbar {
    height: 5px;
    width: 4px;
}

.add-category-modal-inner::-webkit-scrollbar-track,
.add-new-modifier-modal::-webkit-scrollbar-track,
.nutritional-modal::-webkit-scrollbar-track,
.review-card-box::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.add-category-modal-inner::-webkit-scrollbar-thumb,
.add-new-modifier-modal::-webkit-scrollbar-thumb,
.nutritional-modal::-webkit-scrollbar-thumb,
.review-card-box::-webkit-scrollbar-thumb {
    background: #FFB800;
}

.add-product-modal::-webkit-scrollbar {
    height: 5px;
    width: 4px;
}

.add-product-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.add-product-modal::-webkit-scrollbar-thumb {
    background: #FFB800;
}

.add-category-modal .website-image-box {
    max-width: 220px;
    width: 220px;
    height: 208px;
}
.img-fluid{
    max-width: 100%;
    height: auto;
}
.browse-btn{
    border: 0.5px solid #CDCDCDB2 !important;
    color: #4F4F4F !important;
    border-radius: 10px !important;
    text-transform: capitalize !important;
}
.add-category-modal p {
    color: #000;
    font-weight: 600;
    margin: 0px;
    font-size: 14px;
}

.add-new-modifier-modal .input-field-title p {
    margin-bottom: 10px !important;
}

.upload-image-box p {
    color: #403C3C;
    margin-top: 5px;
}

.add-category-modal .modal-btn {
    justify-content: end;
}

.add-category-modal .modal-btn .submit-btn {
    margin-right: 0px;
}

.category-filled {
    width: 100%;
    margin-bottom: 15px;
}

.category-filled label {
    display: block;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 14px;
}

.category-filled input {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 12px 14px;
    color: #4F4F4F;
    opacity: 1;
}

.category-filled input::placeholder {
    color: #4F4F4F;
    opacity: 1;
}

.category-filled fieldset {
    display: none;
}

.category-filled textarea {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    /* height: 85px !important; */
    outline: none;
    padding: 10px;
    font-size: 16px;
}

.category-filled textarea::placeholder {
    padding: 10px;
}

.category-list-inner {
    width: 100%;
}

.category-list {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 25px;
    margin-top: 15px;
}

.add-product-modal .modal-btn .submit-btn {
    margin-left: auto;
}

.add-product-modal .radio-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 15px 0px;
}

.add-product-modal .radio-buttons .Mui-checked span {
    color: #FFB800 !important;
}

.add-product-modal .radio-buttons .MuiRadio-root span {
    color: #D0D5DD;
}

.input-field-title textarea {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    height: 120px !important;
    padding: 15px;
    outline: unset;
    font-size: 14px;
    color: #4F4F4F;
    font-weight: 500;
}

.modifier-accordion .MuiPaper-elevation {
    box-shadow: unset;
    margin: 0px !important;
}

.modifier-accordion .MuiAccordionSummary-root {
    padding: 0px;
    border-bottom: 1px solid #CDCDCDB2;
}

.modifier-accordion .MuiAccordion-root:before {
    display: none;
}

.modifier-accordion textarea {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    height: 85px !important;
    outline: none;
    padding: 10px;
    font-size: 16px;
}

.modifier-accordion {
    margin-bottom: 30px;
}

.modifier-accordion .MuiCollapse-vertical {
    padding-top: 5px;
}

.modifier-category {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 15px 20px;
}

.modifier-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.modifier-detail p {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.modifier-detail svg {
    cursor: pointer;
}

.modifier-category-detail {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 10px;
}

.modifier-category-detail p {
    color: #4F4F4F;
    font-size: 14px;
    font-weight: 500;
}

.add-new-modifier-modal h2 {
    margin-bottom: 20px;
}

.add-icon-box {
    width: 50px;
    height: 45px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    text-align: center;
    line-height: 55px;
    cursor: pointer;
    flex: 0 0 50px;
    align-content: center;
}

.input-field-title .modifier-detail:nth-child(3) .add-icon-box {
    background-color: #33FF00;
}

.input-field-title .modifier-detail:nth-child(4) .add-icon-box {
    background-color: #F90000;
}

.add-new-modifier-modal .modifier-detail {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-ingredient {
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-top: 25px;
    width: 100%;
}

.nutritional-modal {
    height: auto;
    padding-right: 24px;
}

.nutritional-modal .add-icon {
    width: 41px;
    height: 41px;
    background-color: #F5F5F5;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.add-product-model-box {
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.search-ingredient-main {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-bottom: 15px;
}

.nutritional-modal .modal-btn .submit-btn {
    margin-left: 0px;
}

.additive-detail {
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70) !important;
    background-color: #fff;
    border-radius: 10px !important;
    margin-top: 20px;
}

.additive-detail::before {
    display: none;
}

.recipe-values {
    margin-top: 30px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.values-detail {
    max-width: 450px;
    width: 100%;
}

.values-detail label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
}

.values-detail .MuiChip-root {
    background-color: #C8C8C8;
    color: #000;
    font-weight: 500;
}

.values-detail .MuiStack-root {
    flex-wrap: wrap;
    gap: 10px;
}

.recipe-values .recipe-values-wrap label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.recipe-values .MuiTypography-body1 {
    color: #4F4F4F;
    font-size: 15px;
    font-weight: 500;
}

.recipe-values .Mui-checked span {
    color: #FFB800 !important;
}

.recipe-values .MuiTouchRipple-root {
    box-shadow: unset !important;
}

.recipe-values .MuiButtonBase-root span {
    color: #FFB800;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    border-radius: 100%;
}

.recipe-values .MuiSvgIcon-root:last-child {
    left: -8px !important;
    top: -8px;
    font-size: 2.5rem !important;
}

.menu-switch {
    padding-left: 15px;
    max-width: 230px;
    width: 100%;
    display: flex;
    align-items: center;
}

.menu-switch .Mui-checked span {
    color: #FF7A00 !important;
}

.menu-switch .Mui-checked+.MuiSwitch-track {
    background-color: #FFB800 !important;
}

.menu-switch hr {
    height: 100px;
    width: 1px;
    border: 1px solid #000;
    opacity: 1;
}

.additive-detail .MuiAccordionDetails-root {
    padding: 8px 16px 16px !important;
}

.additive-detail .modal-btn {
    margin-top: 40px;
}

.grams-dropdown {
    max-width: 100px;
    width: 100%;

}

.grams-dropdown fieldset {
    display: none;
}

.grams-dropdown #demo-simple-select {
    padding: 10px;
    border: unset !important;
}

.amount-textfield {
    border-bottom: 1px solid #403C3C;
    display: flex;
    align-items: center;
}

.amount-textfield .MuiFormControl-root {
    width: 100px;
}

.amount-textfield input::placeholder {
    color: #403C3C;
    opacity: 1;
    font-weight: 500;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

.qr-code-generator {
    max-width: 280px;
    width: 100%;
}

.qr-code-generator img {
    width: 100%;
}

.qr-code-detail {
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    height: 400px;
    padding: 24px;
}

.qr-code-generator .download-btn {
    background-color: #FFB800 !important;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px 35px;
    width: 100%;
    margin: 15px 0px;
}

.qr-code-generator .vector-btn {
    background-color: #fff !important;
    border: 1px solid #FFB800;
    box-shadow: 0px 2px 5px 2px rgba(205, 205, 205, 0.70);
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px 35px;
    width: 100%;
}

.add-product-modal .configuration-tab {
    margin-top: 20px;
}

.modifier-category-title {
    display: flex;
    align-items: center;
    gap: 15px;
}

.modifier-category-title p {
    font-weight: 600;
    padding-left: 15px;
    font-size: 14px;
}

.modifier-category-title svg {
    cursor: pointer;
}

.category-list-wrap p {
    color: #4F4F4F;
    font-weight: 500;
    margin-right: 8px;
    font-size: 15px;
}

.category-list-wrap span {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.category-list-wrap ul {
    display: flex;
    border-bottom: 1px solid #CDCDCDB2;
    flex-wrap: wrap;
    padding-top: 0px;
}

.category-list-wrap ul li {
    width: unset;
    padding-right: 5px;
}

.category-list-wrap ul li {
    padding-bottom: 0px;
}

.modifier-category-main :has(.MuiAutocomplete-listbox) {
    padding: 15px 0px !important;
}

.modifier-accordion .MuiOutlinedInput-root {
    padding: 0px !important;
}

body:has(.MuiAutocomplete-listbox) .MuiAutocomplete-paper {
    box-shadow: 3px 5px 40px 3px rgba(205, 205, 205, 0.45) !important;
    background-color: #fff !important;
    border-radius: 10px;
}

.modifier-category-wrap {
    margin-bottom: 15px;
}

.input-field-title .add-icon-btn {
    background-color: #fff !important;
    box-shadow: 3px 5px 40px 3px #CDCDCD73;
    min-width: 35px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
}

.modifier-field {
    display: flex;
    align-items: center;
    gap: 10px;
}

.modifier-field .MuiAutocomplete-root {
    width: 100%;
}

.review-star {
    display: flex !important;
    align-items: center;
}

.review-star li {
    padding: 0;
}

.review-star li svg {
    color: #C8C8C8;
}

.add-product-modal-box {
    overflow: auto;
}

.add-product-modal-box .add-category-modal {
    top: inherit;
    transform: translateX(-50%);
}

.nutritional-modal .allergens-dropdown {
    margin-top: 20px !important;
}

.nutritional-modal .allergens-dropdown .MuiStack-root {
    overflow-y: scroll;
    height: 30px;
}

.nutritional-modal .MuiStack-root::-webkit-scrollbar {
    width: 5px;
}

.nutritional-modal .MuiStack-root::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.nutritional-modal .MuiStack-root::-webkit-scrollbar-thumb {
    background: #ffb800;
}

.add-location-modal .location-detail-wrap {
    border: unset;
    width: 100%;
    margin-top: 0px;
    padding: 0px;
}

.add-location-modal .payment-accept-detail {
    margin-top: 20px !important;
    margin-left: 0px;
}

.add-location-modal .add-partner-modal .modal-btn {
    margin: 0px;
    margin-bottom: 15px;
}

.add-location-modal .day-schedule-inner {
    margin-bottom: 10px;
}

.add-location-modal .location-filled-inner {
    margin-bottom: 10px;
}

.add-location-modal .location-detail-wrap {
    padding: 5px 10px;
}

.add-location-modal .add-partner-modal h2 {
    padding-left: 6px;
}


@media (max-width:1400px) {
    .add-product-modal-box .add-category-modal {
        top: 30px;
    }
}

@media (max-width:1199px) {
    .configuration-tab {
        flex-wrap: wrap;
    }

    .add-room-section {
        max-width: 100%;
    }

    .qr-code-generator {
        max-width: 100%;
    }

    .add-product-modal,
    .add-new-modifier-modal,
    .nutritional-modal {
        height: 500px;
        overflow: auto;
    }



    .qr-code-detail {
        height: auto;
    }


}

@media (max-width:991px) {

    .add-location-modal .payment-accept-detail {
        margin-left: 5px;
    }

    .add-location-modal .add-partner-modal .modal-btn {
        margin: 20px 0px;
    }

    .add-product-modal-box .add-category-modal {
        top: inherit;
    }

    .menu-option-detail {
        align-items: baseline;
    }

    .add-product-modal .radio-buttons {
        gap: 0px;
    }

    .search-ingredient {
        margin-top: 15px;
    }

    .food-detail h5,
    .modifier-detail p {
        font-size: 16px;
    }

    .modifier-category {
        padding: 15px;
    }

    .modifier-detail {
        margin-bottom: 10px;
    }

    .payment-accept-detail {
        margin-top: 0px !important;
    }

    .location-filled h5 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .location-detail-btn {
        margin-top: 20px;
    }

    .menu-tab .location-detail-btn {
        text-align: center;
        padding-right: 0px;
    }

    .partner-title .back-btn {
        margin-bottom: 20px;
    }

    .location-img button {
        top: 15px;
        right: 15px;
    }

    .add-category-modal {
        width: 90%;
    }

    .add-category-modal-inner {
        height: 450px;
        overflow: auto;
    }

    .add-category-modal h2 {
        /* margin-bottom: 15px; */
        font-size: 20px;
    }

    .recipe-values {
        flex-wrap: wrap;
    }

    .menu-switch hr {
        display: none;
    }

    .values-detail {
        order: -1;
        max-width: 100%;
        margin-bottom: 15px;
    }
}

@media (max-width:767px) {

    .review-card-box {
        height: 400px;
        overflow-y: auto;
    }

    .payment-accept-detail {
        max-width: unset;
    }

    .location-tab {
        padding-left: 0px;
    }

    .partner-wrap .MuiTabs-flexContainer {
        overflow: auto;
    }

    .menu-list {
        flex-wrap: wrap;
        justify-content: end;
    }

    .configuration-tab {
        flex-wrap: wrap;
    }

    .add-room-section {
        max-width: 100%;
    }

    .categories-list p {
        padding: 20px 0px;
        padding-top: 0px;
        font-size: 18px;
    }

    .categories-list {
        padding: 20px;
    }

    .location-detail-wrap {
        padding: 15px;
    }

    .location-filled h5 {
        font-size: 18px;
    }

    .location-filled-inner label {
        font-size: 14px;
    }

    .menu-option-detail {
        padding: 10px;
    }

    .menu-list ul li {
        padding: 5px;
    }

    .categories-box-active p {
        font-size: 16px;
    }

    .table-border {
        display: none;
    }

    .total-table {
        height: 200px;
        transform: translateY(0%);
        top: 75%;
    }

    .room-tab {
        padding-left: 0px;
    }

    .category-list {
        flex-wrap: wrap;
        gap: 0px;
    }

    .add-category-modal .modal-btn {
        flex-wrap: wrap;
    }

    .upload-image-box {
        width: 100%;
    }

    .add-category-modal .website-image-box {
        max-width: 100%;
    }

    .location-detail-btn {
        margin: 0 auto;
    }

    .location-detail-btn .cancel-btn {
        width: 100%;
        margin-bottom: 15px;
    }

    .modifier-category-detail p {
        font-size: 12px;
    }

}

@media (max-width:575px) {
    .food-box:hover {
        transform: scale(1);
    }

    .location-detail {
        gap: 15px;
    }
    .nutritional-modal {
        height: 80vh ;
    }

}

