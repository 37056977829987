.card-detail {
    padding: 15px;
    border-radius: 10px;
    height: 100px;
}

.card-detail h5 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    opacity: 70%;
    margin-bottom: 5px;
}

.card-detail p {
    font-size: 25px;
    font-weight: 700;
}

@media (min-width:991px) and (max-width:1070px) {
    .card-detail {
        height: 120px;
    }
}

@media (max-width:767px) {
    .card-detail {
        height: auto;
        padding: 10px;
    }

    .card-detail h5 {
        font-size: 16px;
    }

    .card-detail p {
        font-size: 20px;
    }
}