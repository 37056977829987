.sidebar-wrap {
    max-width: 230px;
    width: 100%;
    background-color: #ffffff;
    position: fixed;
    top: 76px;
    width: 100%;
    bottom: 0px;
    overflow: auto;
    padding-right: 20px;
    padding-left: 20px;
    border-right: 2px solid #CBCAC8;
}

.sidebar-wrap::-webkit-scrollbar {
    width: 5px;
}

.sidebar-wrap::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sidebar-wrap::-webkit-scrollbar-thumb {
    background: #ffb800;
}

.sidebar-wrap ul {
    padding-top: 25px;
}

.sidebar-wrap ul li {
    cursor: pointer;
    padding: 15px;
    width: 100%;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
    transition: all 0.5s;
    /* justify-content: center; */
    max-width: 120px;
    color: #000000;
    font-weight: 400;
}

.sidebar-menu {
    transition: all 0.5s;
    cursor: pointer;
}

.sidebar-menu-active {
    background-color: #FFFAF5;
    cursor: pointer;
    border-left: 2px solid #FFB800 ;
}

.sidebar-wrap ul .sidebar-menu:hover {
    background-color: #FFFAF5;
    border-left: 2px solid #FFB800 ;
}

.sidebar-wrap ul li span {
    display: contents;
}

.sidebar-wrap ul li a {
    text-decoration: none;
    color: #000000;
    font-weight: 400;
    font-size: 15px;
}

@media (max-width:991px) {
    .sidebar-wrap {
        left: -100%;
        transition: all 0.5s;
    }
}